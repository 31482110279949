export const environment = {
  production: true, 
  appName: 'Visitare Prezza',
  appTitle: 'Visitare Prezza',
  appUrl: 'visitareprezza.it',
  prefix: 'ss_prz_',
  basePath: 'https://visitareprezzaapi.devster.it',
  frontendBasePath: 'https://visitareprezza.devster.it',
  apiBasePath: 'https://visitareprezzaapi.devster.it/api',
  wordPressBasePath: 'https://visitareprezza.it/engine/', 
  wordPressRestpath: '/wp-json', 
  wordPressRestVersion: '/v2', 
  wooCommerceRestPath: '/wc', 
  wooCommerceRestVersion: '/v3', 
  wooCommerceConsumerKey: 'ck_24cb268d17164b31ee7567e7b976751f20145693', 
  wooCommerceConsumerSecret: 'cs_a957897d51c1603d4386d3746da2b28147f16d12', 
  wordpressCustomTaxonomyRestPath: '/wce',
  wordpressCustomTaxonomyRestVersion: '/v3',
  googleMapsAddressLink: 'https://www.google.com/maps/search/?api=1&query=',
  currency: "€",
  defaultNoImage: 'assets/img/bg-profile.jpg',
};