<div class="header py-7 py-lg-8 demo-wrap bg-hero" *ngIf="product" 
    style="--radius: url({{environment.basePath}}/img/{{product.type}}/{{product.slug}}/{{product.featured_image}});"
>
<!-- style="--radius: url({{environment.basePath}}/api/files/slug/{{product.slug}}/{{product.featured_image}});" -->
  <!-- header bg-gradient-danger py-7 py-lg-8 demo-wrap -->
    <div class="container demo-content">
      <div class="header-body text-center mt-5 mb-7">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12">
            <h1 class="text-white">{{activity.name}}</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div> -->
</div>
  <!-- Page content -->
<div class="mt--8 pb-5 mt-n7 m-3" *ngIf="product">
  <div class="row">
      <div class="col-lg-8 col-md-8 col-xs-12 mb-3 mb-sm-3 mb-md-3 mb-lg-0 padd-bott">
        <div class="card shadow border-0">
            <img class="card-img-top" 
                src="{{environment.basePath}}/img/{{product.slug}}/{{product.featured_image}}"
                alt="{{product.slug}}" *ngIf="isVisible"
            >
            <!-- src="{{environment.basePath}}/images/products/{{product.slug}}/{{product.featured_image}}" -->
            <div class="card-body">
              <!-- <div class="row justify-content-center"> -->
              <!-- <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 text-center" *ngIf="productMetaData.telefono">
                  <h5 class="card-title">
                    <a href="tel:{{productMetaData.telefono}}"><i class="fa fa-2x fa-phone"></i></a>
                  </h5>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 text-center" *ngIf="productMetaData.email">
                  <h5 class="card-title">
                    <a href="mailto:{{productMetaData.email}}"><i class="ni fa-2x ni-email-83"></i></a>
                  </h5>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 text-center" *ngIf="productMetaData.indirizzo">
                  <h5 class="card-title">
                    <a href="{{googleMapsAddressLink}}{{productMetaData.indirizzo}}" target="_blank">
                      <i class="ni fa-2x ni-pin-3"></i>
                    </a>
                  </h5>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 text-center" *ngIf="productMetaData.sito_web">
                  <h5 class="card-title">
                    <a href="{{productMetaData.sito_web}}" target="_blank">
                      <i class="fas fa-2x fa-globe"></i>
                    </a>
                  </h5>
                </div>
              </div> -->
              <div class="row justify-content-center">
                  <div class="col-lg-12 col-md-12 col-xs-12">
                      <h4 class="card-title">{{product.title}}</h4>
                      <p class="card-text" [innerHTML]="product.content"></p>
                      <!-- <p class="card-text">{{slug}}!</p> -->
                      <!-- <a  class="btn btn-primary" [routerLink]="['/', product.slug]">Vai alla descrizione</a> -->
                      <a  class="btn btn-primary" (click)="back()">Torna indietro</a>
                  </div>
              </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-4 col-xs-12 padd-bott">
      <div class="card shadow border-0">
          <img class="card-img-top" 
              src="{{environment.basePath}}/img/{{product.slug}}/{{product.featured_image}}"
              alt="{{product.slug}}" *ngIf="isVisible"
          >
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-5 text-center" *ngIf="product.phone">
                <h5 class="card-title">
                  <a href="tel:{{product.phone}}">
                    <!-- <i class="fa fa-2x fa-phone"></i> -->
                    <i class="fas fa-2x fa-phone"></i>
                  </a>
                </h5>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-5 text-center" *ngIf="product.email">
                <h5 class="card-title">
                  <a href="mailto:{{product.email}}">
                    <!-- <i class="ni fa-2x ni-email-83" style="font: normal normal normal 2rem / 1 NucleoIcons;"></i>-->
                    <i class="fas fa-2x fa-envelope"></i>
                  </a>
                </h5>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-5 text-center" *ngIf="product.address">
                <h5 class="card-title">
                  <a href="{{googleMapsAddressLink}}{{product.address}}" target="_blank">
                    <!-- <i class="ni fa-2x ni-pin-3" style="font: normal normal normal 2rem / 1 NucleoIcons;"></i> -->
                    <i class="fas fa-2x fa-map-marker-alt"></i>
                  </a>
                </h5>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-1 text-center" *ngIf="product.website">
                <h5 class="card-title">
                  <a href="{{product.website}}" target="_blank">
                    <i class="fa fa-2x fa-globe"></i>
                    <!-- <i class="fas fa-globe"></i> -->
                  </a>
                </h5>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>