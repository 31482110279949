<!-- <div *ngIf="product"> -->
    <!-- <div class="col-md-12 mb-5" *ngFor="let item of product"> -->
        <div class="col-md-12 mb-5">
            <form name="post-new" autocomplete="off" enctype="multipart/form-data" novalidate [formGroup]="form">
            <div class="row">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-header pb-0">
                            <div class="d-flex align-items-center">
                                <p class="mb-0">{{pageInformation.title}}</p>
                                <!-- <button class="btn btn-primary btn-sm ms-auto">Settings</button> -->
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="text-uppercase text-sm">Base Info</p>
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label" for="title">{{pageInformation.title}}</label>
                                        <input type="text" name="title" id="title"
                                            class="form-control form-control-alternative mb-2" (change)="buildPermalink()"
                                            placeholder="Title" value="" required autofocus formControlName="title"
                                        />
                                        <!-- @if ($errors->has('title'))
                                            <span class="invalid-feedback" role="alert">
                                                <strong>{{ $errors->first('title') }}</strong>
                                            </span>
                                        @endif -->
                                        <span class="slug">Permalink:</span>
                                        <span id="slug-span" class="input-read-only">
                                            {{form.controls['slug'].value}}
                                        </span>
    
                                        <input type="hidden" name="slug" id="slug" value="">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label" for="subTitle">Subtitle</label>
                                        <input type="email" name="subTitle" id="subTitle" formControlName="subTitle"
                                                class="form-control form-control-alternative"
                                                placeholder="Subtitle" value="" required
                                        />
                                        <!-- @if ($errors->has('subtitle'))
                                            <span class="invalid-feedback" role="alert">
                                                <strong>{{ $errors->first('subtitle') }}</strong>
                                            </span>
                                        @endif -->
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label for="catalog_visibility" class="form-control-label">Catalog Visibility</label>
                                        <select class="form-control" name="catalog_visibility" id="catalog_visibility">
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group" *ngIf="categories">
                                        <label for="category_id" class="form-control-label">Category ID</label>
                                        <select class="form-control" name="category" id="category" formControlName="category">
                                            <option value="" disabled>Select one</option>
                                            <option value="{{category._id}}" *ngFor="let category of categories">{{category.title}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr class="horizontal dark"> <!-- ///////////////////////////////////////////// -->
                            <div class="row">
                                <div class="col-12 col-md-6 col-xl-6">
                                    <div class="form-group">
                                        <label for="type" class="form-control-label">Type</label>
                                        <select class="form-control" name="type" id="type" formControlName="type">
                                            <option value="">Select one</option>
                                            <option value="{{postType}}" *ngFor="let postType of postTypes">
                                                {{postType}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-xl-6">
                                    <div class="form-group">
                                        <label for="status" class="form-control-label">Status</label>
                                        <select class="form-control" name="status" id="status" formControlName="status">
                                            <option value="">Select one</option>
                                            <option value="{{postStatus}}" *ngFor="let postStatus of postStatuses">
                                                {{postStatus}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4 col-xl-4">
                                    <label class="form-control-label" for="enabled"></label>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" name="enabled" id="enabled"
                                            formControlName="enabled" checked="false" 
                                        />
                                        <label class="form-check-label" for="enabled">
                                            Enabled
                                        </label>
                                    </div>
                                </div>
                                <div class="col-8 col-md-8 col-xl-8">
                                    <label class="form-control-label" for="featured_image_obj">Featured Image</label>
                                    <div class="form-group">
                                        <input #fileUpload type="file" name="featured_image_obj" id="featured_image_obj" 
                                        class="form-control" (change)="uploadFile($event)"
                                        />
                                        <!-- onchange="console.log(event.target.files)" -->
                                    </div>
                                </div>
                            </div>
                            <hr class="horizontal dark"> <!-- ///////////////////////////////////////////// -->        
                            <p class="text-uppercase text-sm">Short Description</p>
                            <div class="row">
                                <div class="col-12">
                                    <div class="text-center">
                                        <div class="form-group">
                                            <textarea type="text" name="short_description" id="short_description" 
                                                placeholder="" required rows="2"
                                                class="form-control form-control-alternative" formControlName="short_description"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <p class="text-uppercase text-sm">Content</p>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <editor
                                            class="form-control form-control-alternative"
                                            name="content" id="content" placeholder="Content"
                                            formControlName="content"
                                            [init]="{ plugins: 'lists link image table code help wordcount' }"
                                        ></editor>
                                        <span class="invalid-feedback" role="alert">
                                            <strong></strong>
                                        </span>
                                    </div>
                                </div>
                            </div>    
    
    
                            <div class="row">
                                <div class="col-6">
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-info mt-3 float-start" (click)="back()">
                                            Back
                                        </button>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="text-center justify-content-end">
                                        <button type="submit" class="btn btn-warning mt-3 float-end" 
                                            (click)="productId ? update() : save()"
                                        >
                                        {{productId ? 'Update' : 'Save'}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr class="my-4" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-profile" *ngIf="product else baseInformationCard">
                        <img class="card-img-top" alt="{{product.slug}}" *ngIf="product.featured_image"
                            [src]="(product.featured_image || product.featured_image != '' ) ? 'http://localhost:3000/uploads/'+product.featured_image : '/assets/img/no_image_product.jpg'"
                        >
                        <hr class="my-3" />
                        <app-timeline-one
                            [steps]="[
                                {name: 'created', description: product.created_at | date:'dd-MM-yyyy hh:mm:ss' },
                                {name: 'last update', description: product.updated_at | date:'dd-MM-yyyy hh:mm:ss' },
                            ]"
                        >
                        </app-timeline-one>
                    </div>
    
                    <ng-template #baseInformationCard>
                        <div class="card card-profile">
                            <!-- <img src="assets/img/bg-profile.jpg" alt="Image placeholder" class="card-img-top" />
                            <img src="assets/img/no_image_product.jpg" alt="Image placeholder" class="card-img-top" /> -->
                            <img class="card-img-top" alt="immagine demo" [src]="'/assets/img/no_image_product.jpg'">
                            <hr class="my-3" />
                            <app-timeline-one
                                [steps]="[
                                    {name: 'created', description: '2023-10-22T14:31:29.791+00:00' | date:'dd-MM-yyyy hh:mm:ss' },
                                    {name: 'last update', description: '2023-10-22T14:31:29.791+00:00' | date:'dd-MM-yyyy hh:mm:ss' },
                                ]"
                            >
                            </app-timeline-one>
                        </div>
                    </ng-template>
                </div>
            </div>
            </form>
        </div>
    <!-- </div> -->