import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services-common/common.service';
import { InventoryService } from 'src/app/services-common/inventory.service';
import { MoneyManagerService } from 'src/app/services-common/money-manager.service';
import { environment } from 'src/environments/environment';
import { TransactionTypeEnum } from 'src/app/Enums/TransactionTypeEnum';
import { SlugUtils } from 'src/app/utils-common/SlugUtils';
import { animate, style, transition, trigger } from "@angular/animations";
import { Observable } from 'rxjs';
import { encode } from 'base64-arraybuffer';
import { FileService } from 'src/app/services-common/file.service';


@Component({
  selector: 'app-money-categories',
  templateUrl: './money-categories.component.html',
  styleUrls: ['./money-categories.component.scss'],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ background: "red" }),
        animate("1s ease-out", style({})),
      ]),
    ]),
  ],
})
export class MoneyCategoriesComponent implements OnInit, OnDestroy {

  status: "initial" | "uploading" | "success" | "fail" = "initial"; // Variable to store file status
  file: File | null = null; // Variable to store file
  fileCoded!: any;

  public enableDestination!: boolean;
  public categories!: any;
  public categoryForm = new FormGroup({
    user_id: new FormControl('1'),
    title: new FormControl(''),
    slug: new FormControl(''),
    color: new FormControl(''),
    featured_image: new FormControl(''),
    content: new FormControl(''),
  });
  public transactionTypes: string[] = [
    TransactionTypeEnum.EXPENSE,
    TransactionTypeEnum.INCOME,
    TransactionTypeEnum.TRANSFER
  ];

  constructor(
    private titleService:Title,
    // private location: Location,
    private commonService: CommonService,
    private moneyManagerService: MoneyManagerService,
    private modalService: NgbModal,
    private fileService: FileService
  ) { }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(`${environment.appName}`);
    await this.init();
  }


  async init(): Promise<void> {
    this.commonService.AClicked(true);
    this.moneyManagerService
      .findAllCategories()
      .subscribe((data: any) => {
        // console.warn(data);
        this.categories = data;
        // console.log(this.accounts);
        this.commonService.AClicked(false);
      });
  }


  search(){
    alert('mimmo');
  }

  openXl(content: TemplateRef<any>) {
		this.modalService.open(content, { size: 'xl' });
	}

  modalClose() {
    // modal.close('Close click')
    this.modalService.dismissAll();
    this.categoryForm.reset();
  }

  open(content: TemplateRef<any>, $event: any) {
    this.categoryForm.patchValue(
      $event
    );
    this.modalService.open(content, { size: 'xl' });
  }


  buildPermalink() {
    if (this.categoryForm.controls['title'].value) {
      let Text: string|null = this.categoryForm.controls['title'].value;
      let TextSlugged       = SlugUtils.convertToSlug(Text);
      this.categoryForm.patchValue({
        slug: TextSlugged
      });
      console.warn(TextSlugged);
    }
  }

  async uploadFile(event: any): Promise<any> {
    const file: File = event.target.files[0];
    // console.log(file);

    if (file) {
      this.status = "initial";
      this.file = file;
      this.fileCoded = await this.transformAttachments( event.target.files );
      this.categoryForm.patchValue({featured_image: this.fileCoded[0].name})
      console.warn(this.fileCoded);
    }
  }

  async transformAttachments( files: any ){
      let filesTransformed: any = [];

      for (let index = 0; index < files.length; index++) {
          let item = files[index];
          let fileContent = await this.getBase64EncodedFileData( item );
          let file = {
              name    : item.name,
              tipology   : item.type,
              type    : item.name.split('.').pop(),
              data        : fileContent
          };
          filesTransformed.push( file );
      }
      return filesTransformed;
  }

  public getBase64EncodedFileData(file: File): Promise<any> {
      return new Observable(observer => {
          const reader = new FileReader();

          reader.onload = (): void => {
              const {result} = reader;
              const data = result as ArrayBuffer;
              const base64Encoded = encode(data);

              observer.next(base64Encoded);
              observer.complete();
          };

          reader.onerror = (): void => {
              observer.error(reader.error);
          };

          reader.readAsArrayBuffer(file);
      }).toPromise();
  }

  async saveEntity(): Promise<void> {
    this.commonService.AClicked(true);
    console.warn(this.categoryForm.value);
    await this.fileService.create(this.fileCoded);
    this.moneyManagerService.createCategory(this.categoryForm.value)
      .subscribe((data: any) => {

      });
    this.modalService.dismissAll();
    this.categoryForm.reset();
    this.init();
    this.commonService.AClicked(false);
  }

  ngOnDestroy(): void {
    
  }

}
