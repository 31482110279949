import { Component, OnDestroy, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import {environment} from 'src/environments/environment';
import { RouteInformation } from 'src/app/interfaces-common/RouteInformation';
import { CommonService } from 'src/app/services-common/common.service';
import { Activity } from 'src/app/interfaces-common/Activity';
import { ProductAndServicesService } from 'src/app/services-common/product-and-services.service';


@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit, OnDestroy {

  public activity: Activity = {};
  public isVisible: boolean = false;
  public routeInformation: RouteInformation = {};
  public product!: any;
  public googleMapsAddressLink!: string;
  public environment: any = environment;

  constructor(
    private route: ActivatedRoute,
    private titleService:Title,
    private location: Location,
    private productAndService: ProductAndServicesService,
    private commonService: CommonService
  ) { }



  async ngOnInit(): Promise<void> {
    // let all = this.route.url.subscribe( val => {
    //   let baseRoute = val[0].path;
    //   this.activityType = baseRoute;
    // });

    this.route.paramMap.subscribe( async paramMap => {
      this.routeInformation.slug = paramMap.get('slug');
      this.googleMapsAddressLink = `${environment.googleMapsAddressLink}`;
      this.commonService.AClicked(true);
      
      if (this.routeInformation.slug) {
        await this.initComponent(this.routeInformation.slug);
      }
      this.commonService.AClicked(false);
      this.titleService.setTitle(`${environment.appUrl} - ${this.activity.name}`);
    });
  }


  async initComponent(productDetailSlug: string): Promise<void> {
    this.product = (await this.productAndService.getProductBySlug(productDetailSlug))[0];
    this.activity.name = this.product.title;
    console.log(this.product);
  }

  public back(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    
  }

}
