<div class="card shadow-lg mx-4 card-profile-bottom-medium mt-5">
    <div class="card-body p-3">
        <div class="row gx-4 d-md-flex justify-content-end">
            <div class="col-sm-4 col-md-4 col-lg-4">
                <div class="d-grid gap-2 d-md-flex justify-content-end">
                    <!-- <button class="btn btn-outline-primary mb-2 me-2" (click)="openXl(content)">
                    Add Transaction
                    </button> -->
                    <button class="btn btn-outline-primary mb-2 me-2 text-capitalize" (click)="add()">
                        Add {{type}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="row mt-6">
        <div class="col-md-12" *ngIf="type">
            <div class="col-md-4 mb-5" *ngFor="let taxonomy of taxonomies">
                <app-taxonomy-info-card 
                    [item]="taxonomy"
                    [type]="type"
                    [baseRoute]="baseTaxonomyCardRoute"
                ></app-taxonomy-info-card>
            </div>
        </div>
    </div>
</div>

<!-- (modalUpdateOpener)="open(content, $event)" -->