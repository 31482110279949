<app-hero 
  url="{{environment.frontendBasePath}}/assets/img/banner-img.jpg"
  title="Prezza" subTitle="Il terrazzo sulla valle Peligna"
></app-hero>

<div class="container mt--6 pb-5 col-12">
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12">
      <!-- <div class="card bg-secondary shadow border-0"> -->
          <div class="row justify-content-center">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 text-center mt-n7"
                style="z-index: 10;"
              >
                <h2 class="" style="font-size: 2rem;">
                  <q cite="">La vita è un viaggio e chi viaggia vive due volte.</q>
                  <p style="text-align: right;">ʿUmar Khayyām</p>
                </h2>
              </div>
          </div>
    </div>
  </div>
</div>


<div class="container mt--3 pb-5 col-12">
  <div class="row justify-content-center">
    <!-- <div class="col-lg-4 col-md-4 card-hover" *ngFor ="let category of categories;">
      <a href="" class="" [routerLink]="['/categorie/', category.slug]">
        <div class="card bg-secondary shadow border-0" 
            style="
            background-image: url( {{environment.basePath}}/images/taxonomies/product-category/{{category.slug}}/{{category.featured_image}} ); 
              background-position: center; background-repeat: no-repeat; background-size: cover;"
        >
            <div class="card-header pb-5" style="background-color: #54545499;border-radius: 1rem;">
            <div class="row justify-content-center">
              <div class="card-body">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 text-center">
                    <h2 class="text-white" style="font-size: 2rem;">{{category.title}}</h2>
                  </div>
              </div>
            </div>
          </div> 
        </div>
      </a>
    </div> -->
    <div class="col-lg-4 col-md-4 card-hover" *ngFor ="let productCategory of productCategories;">
      <a href="" class="" [routerLink]="['/categorie/', productCategory.slug]">
        <div class="card bg-secondary shadow border-0" 
            style="
            background-image: url( {{environment.basePath}}/img/product-categories/{{productCategory.slug}}/{{productCategory.featured_image}} ); 
              background-position: center; background-repeat: no-repeat; background-size: cover;"
        >
            <div class="card-header pb-5" style="background-color: #54545499;border-radius: 1rem;">
            <div class="row justify-content-center">
              <div class="card-body">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 text-center">
                    <h2 class="text-white" style="font-size: 2rem;">{{productCategory.title}}</h2>
                  </div>
              </div>
            </div>
          </div> 
        </div>
      </a>
    </div>
  </div>
</div>




<!-- Page content -->
<!-- <div class="container mt--8 pb-5"> -->
<!-- <div class="container mt--8 pb-5 col-12"> -->
<div class="container mt--2 pb-5 col-12">
  <!-- <app-ricerca></app-ricerca> -->
  <!-- <ngb-carousel *ngIf="images">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
          <img [src]="images[0]" alt="Random first slide" style="width: 100%;">
      </div>
      <div class="carousel-caption">
        <h3>RIEVOCAZIONE DEL MALMOZZETTO</h3>
        <p></p>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img [src]="images[1]" alt="Random second slide" style="width: 100%;">
      </div>
      <div class="carousel-caption">
        <h3>LA RUZZOLA</h3>
        <p></p>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img [src]="images[2]" alt="Random third slide" style="width: 100%;">
      </div>
      <div class="carousel-caption">
        <h3>SAGRA DEL CARCIOFO DI PREZZA</h3>
        <p></p>
      </div>
    </ng-template>
  </ngb-carousel> -->

  <app-eventi-principali></app-eventi-principali>

  <div class="card bg-gradient-smooth mt-5">
    <div class="row justify-content-center">
      <!-- <div class="col-lg-10 col-md-10 col-xs-10"> -->
        <div class="col-7">
        <div class="card-body">
          <h3 class="card-title text-white">Quando visitarci?</h3>
          <blockquote class="blockquote text-white mb-0">
              <p>Tutto l'anno! I turisti e gli appassionati di borghi sono sempre i benvenuti!</p>
              <!-- <footer class="blockquote-footer text-danger">Someone famous in <cite title="Source Title">Source Title</cite></footer> -->
          </blockquote>
        </div>
      </div>
      <!-- <div class="col-lg-2 col-md-2 col-xs-2"> -->
      <div class="col-2">
        <p></p>
        <svg 
        width="100" height="100"
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <g>	<g> <path d="M467.251,111.359c-28.582-41.669-68.35-73.705-115.002-92.647c-5.12-2.078-10.957,0.387-13.038,5.508
        c-2.079,5.121,0.387,10.957,5.508,13.038c43.005,17.461,79.669,47,106.025,85.423c26.978,39.332,41.24,85.432,41.24,133.319
        c0,130.124-105.862,235.985-235.985,235.985S20.015,386.122,20.015,256S125.876,20.015,256,20.015
        c5.527,0,10.007-4.481,10.007-10.007C266.007,4.481,261.527,0,256,0C114.84,0,0,114.84,0,256s114.84,256,256,256
        s256-114.84,256-256C512,204.053,496.526,154.037,467.251,111.359z"/></g></g><g>
        <g><path d="M212.878,136.432c-23.944,0-43.424,19.48-43.424,43.424c0,5.527,4.481,10.007,10.007,10.007
        c5.527,0,10.007-4.481,10.007-10.007c0-12.908,10.502-23.409,23.409-23.409s23.409,10.502,23.409,23.409
        c0,5.527,4.481,10.007,10.007,10.007c5.527,0,10.007-4.481,10.007-10.007C256.302,155.912,236.822,136.432,212.878,136.432z"/></g>
        </g><g><g><path d="M381.9,136.432c-23.944,0-43.424,19.48-43.424,43.424c0,5.527,4.481,10.007,10.007,10.007
          c5.527,0,10.007-4.481,10.007-10.007c0-12.908,10.502-23.409,23.409-23.409c12.907,0,23.409,10.502,23.409,23.409
          c0,5.527,4.481,10.007,10.007,10.007s10.007-4.481,10.007-10.007C425.324,155.912,405.845,136.432,381.9,136.432z"/></g>
        </g><g>	<g><path d="M391.365,311.594H192.219c-5.527,0-10.007,4.481-10.007,10.007c0,60.423,49.157,109.581,109.581,109.581
          s109.58-49.157,109.58-109.581C401.372,316.075,396.891,311.594,391.365,311.594z M380.802,331.609
          c-4.992,44.695-43.006,79.558-89.011,79.558s-84.019-34.863-89.011-79.558L380.802,331.609L380.802,331.609z"/></g>
        </g><g><g>	<circle cx="310.868" cy="17.167" r="10.007"/></g>
        </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="card-body px-lg-5 py-lg-5">
      <div class="text-center text-muted mb-4">
        <h2>CONTATTACI!</h2>
      </div>
    </div>
  </div>

  
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div class="card text-center">
        <div class="card-body">
          <h5 class="card-title">Email
            <a class="my-4" href="mailto:info@visitareprezza.it" >info&#64;visitareprezza.it</a>
          </h5>
        </div>
        <div class="card-body">
          <h5 class="card-title">Telefono
            <a class="my-4" href="tel:+39086445138">+39 0864 45138</a>
          </h5>
        </div>

      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div class="card text-center">
        <div class="card-body">
          <h5 class="card-title">Info</h5>
          <form role="form">
            <div class="form-group">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="ni ni-single-02"></i></span>
                </div>
                <input class="form-control" placeholder="Antonio" type="text">
              </div>
            </div>
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                </div>
                <input class="form-control" placeholder="Email" type="email">
              </div>
            </div>
            <div class="text-center">
              <button type="button" class="btn btn-primary my-4">Invia</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>
