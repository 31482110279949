
<div class="wrapper">
    <div class="overlay" *ngIf="isVisible;else base">
      <div class="spinner-wrapper">
        <!-- <app-spinner></app-spinner> -->
        <div class="container">
            <div class="row">
                <div id="loader">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="loading"></div>
                </div>
            </div>
        </div>
      </div>
    </div>
  
    <!-- <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div> -->
</div>



<ng-template #base>
  <!-- ciao -->
</ng-template>
  
