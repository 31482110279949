import { Component } from "@angular/core";


export enum PostStatusEnum {
    NEW         = "NEW",
    ACTIVE      = "ACTIVE",
    INACTIVE    = "INACTIVE",
    PENDING     = "PENDING",
    CLOSED      = "CLOSED",
    DELETED     = "DELETED",
    REVOKED     = "REVOKED",
    PROCESSING  = "PROCESSING",
    ACCEPTED    = "ACCEPTED",
    REJECTED    = "REJECTED",
}