import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-overlayed',
  templateUrl: './card-overlayed.component.html',
  styleUrls: ['./card-overlayed.component.scss']
})
export class CardOverlayedComponent implements OnInit {

  @Input() imgUrl!: string;
  constructor() { }

  ngOnInit(): void {
  }

}
