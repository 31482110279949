import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services-common/common.service';
import { InventoryService } from 'src/app/services-common/inventory.service';
import { MoneyManagerService } from 'src/app/services-common/money-manager.service';
import { environment } from 'src/environments/environment';
import { TransactionTypeEnum } from 'src/app/Enums/TransactionTypeEnum';


@Component({
  selector: 'app-money-manager',
  templateUrl: './money-manager.component.html',
  styleUrls: ['./money-manager.component.scss']
})
export class MoneyManagerComponent implements OnInit, OnDestroy {

  public enableDestination!: boolean;
  public accounts!: any;
  public categories!: any;
  public transactions!: any[];
  public transactionForm = new FormGroup({
    user_id: new FormControl('1'),
    date: new FormControl(''),
    title: new FormControl(''),
    type: new FormControl(''),
    account: new FormControl(''),
    destinationAccount: new FormControl(),
    category: new FormControl(''),
    amount: new FormControl(''),
    content: new FormControl(''),
  });
  public transactionTypes: string[] = [
    TransactionTypeEnum.EXPENSE,
    TransactionTypeEnum.INCOME,
    TransactionTypeEnum.TRANSFER
  ];

  constructor(
    private titleService:Title,
    // private location: Location,
    private commonService: CommonService,
    private moneyManagerService: MoneyManagerService,
    private modalService: NgbModal    
  ) { }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(`${environment.appName}`);
    await this.init();
  }


  async init(): Promise<void> {
    this.commonService.AClicked(true);
    this.moneyManagerService
      .findAllTransactions()
      .subscribe((data: any) => {
        // console.warn(data);
        this.transactions = data;
        // console.log(this.transactions);
        this.commonService.AClicked(false);
      });

    this.commonService.AClicked(true);
    this.moneyManagerService
      .findAllAccounts()
      .subscribe((data: any) => {
        // console.warn(data);
        this.accounts = data;
        // console.log(this.accounts);
        this.commonService.AClicked(false);
      });

    this.commonService.AClicked(true);
    this.moneyManagerService
      .findAllCategories()
      .subscribe((data: any) => {
        console.warn(data);
        this.categories = data;
        // console.log(this.accounts);
        this.commonService.AClicked(false);
      });
  }


  search(){
    alert('mimmo');
  }

  openXl(content: TemplateRef<any>) {
		this.modalService.open(content, { size: 'xl' });
	}

  enableDestinationFound(value: string) {
    console.warn(value);
    if(value === 'TRANSFER'){
      this.enableDestination = true;
    }else {
      this.enableDestination = false;
    }
  }

  saveTransaction() {
    console.warn(this.transactionForm.value);
    this.moneyManagerService.createTransaction(this.transactionForm.value)
      .subscribe((data: any) => {

      });
    this.modalService.dismissAll();
    this.transactionForm.reset();
    this.init();
  }

  ngOnDestroy(): void {
    
  }

}