<!-- <div class="header bg-hero py-7 py-lg-8"> -->
<div class="header py-7 py-lg-8 demo-wrap" style="--radius: url('https://visitareprezza.it/engine/wp-content/uploads/2021/03/banner-img.jpg');">
    <div class="container demo-content">
        <div class="header-body text-center mb-7">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
            <h1>Contatti e Credits</h1>
            <p class="text-lead">Informazioni di base sul progetto</p>
            </div>
        </div>
        </div>
    </div>
    <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
    </div>
    </div>
    <!-- Page content -->
    <!-- <div class="container mt--8 pb-5"> -->
    <div class="container mt--8 pb-5 col-12 mt-n5">
    <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12">
            <div class="card bg-secondary shadow border-0">
                <div class="card-header pb-5">
                <!-- <div class="card-header bg-transparent pb-5"> -->
                    <!-- <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div> -->
            
                    <!-- ///////////////////////////////////////////////////////////////////////// -->
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-3">
                            <!-- <i class="fa fa-3x fa-smile text-center"></i> -->
                            <h3>Perchè questo progetto</h3>
                        </div>
                        <div class="col-lg-9 col-md-9">
                            <div class="btn-wrapper text-center"></div>
                            <p>Questo progetto è nato per soddisdare diverse esigenze:
                                <ul>
                                    <li>la voglia di sviluppare un applicativo basato su le più recenti tecnologie 
                                        (che spiegherò in seguito)</li>
                                    <li>il desiderio di creare qualcosa per un piccolo paesino che, in qualche modo, ha 
                                        accolto me e la mia famiglia</li>
                                </ul>
                            <p>
                                Prezza è un piccolo comune nella provincia dell’Aquila. Uno splendido borgo medievale che 
                                spunta sulla sommità di una collina. (ma questo lo sapete già dalla homepage del progetto ;) )
                            </p>
                            <p> È un paesino che mi ha permesso di avere un posto sempre <strong>"online"</strong> dove andare 
                                quando ho bisogno di staccare la spina, quando allontanarsi dalla città è la soluzione migliore.
                            </p>
                            <p>
                                È anche il posto dove riconnettersi con la natura è facile, accessibile. Ad un tiro di schioppo 
                                abbiamo Il <strong>Morrone</strong>, ad una distanza inferiore le <strong>Gole di san Venazio</strong>
                                che, con il loro Eremo e la valle che lo circonda, trasportano il visitatore in un'altra dimensione
                            </p>
                        </div>
                    </div>
                    <br><br><br>
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-3">
                        <h3>Tecnologie utilizzate</h3>
                        </div>
                        <div class="col-lg-9 col-md-9">
                        <p>
                            Il progetto è stato sviluppato seguendo un architettura <strong>frontend / backend</strong>. <br>
                            Per il non addetti ai lavori vi lascio una breve (e non completa) definizione di 
                            questa architettura.
                        </p>
                            <h4>FRONTEND</h4>
                        <p>
                            Il frontend è rappresentato dal software che gestisce la parte visibile 
                            dell'applicazione (disposizione immagini, adattabilità alle risoluzioni di diversi schermi) ed è 
                            quella che si occupa di interagire con il backend. Nel momento in cui accedete a questo sito,
                            il software viene totalmente caricato dal vostro browser preferito ed eseguito, permettendovi di
                            navigare tra le pagine. Il vantaggio è rappresentato da una migliore gestione delle risorse.
                        </p>
                        <p>
                            Quando cambierete pagina il sistema si accorgerà di ciò che effettivamente è stato aggiornato 
                            ed andrà a modificare solo quelle piccole porzioni, evitandovi un caricamento della pagina completa
                            come accade con i progetti <strong>monolitici</strong>
                        </p>
                        <p>
                            Per il frontend sono state utilizzate le seguenti tecnologie:
                            <ul>
                                <li>Angular</li>
                                <li>Typescript</li>
                                <li>Bootstrap 5.x</li>
                                <li>NgBootstrap 15.x</li>
                            </ul>
                        <p>
                            <h4>BACKEND</h4>
                            Il backend è la parte del progetto che fornisce i contenuti, a seconda della pagina richiesta. <br>
                            Si occupa di fornire le immagini, i testi, gestire l'autenticazione dell'utente (in caso fosse disponibile)
                            parlare con il database per richiedere i contenuti giusti.<br>
                            Per il backend sono state utilizzate le seguenti tecnologie:
                            <ul>
                                <li>NestJs (REST Api) 9+</li>
                                <li>Node 20+</li>
                                <li>MongoDb 8+</li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-3">
                            <h4>Contatti per il progetto</h4>
                        </div>
                        <div class="col-lg-9 col-md-9">
                            <p>
                                Utilizzando il form di contatto qui sotto non contatterete l'Amministrazione comunale di Prezza, 
                                ma lo sviluppatore del progetto (che sarei <a href="https://stefanosponsillo.net" target="_blank">io</a>)
                            </p> 
                            <p>Per contattare l'Amministrazione i recapiti sono i seguenti:</p> 
                            <ul>
                                <li><strong>Email: </strong><a href="mailto:protocollo@comune.prezza.aq.it">protocollo@comune.prezza.aq.it</a></li>
                                <li><strong>Telefono: </strong><a href="tel:+39086445138">+39 0864 45138</a></li>
                            </ul>
                            <p>
                                Per Informazioni sul progetto o per essere inseriti tra le strutture ricettive di Prezza 
                                (b&b, birrerie, enoteche, ecc) potete invece utilizzare il form di contatto qui sotto
                            </p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="card-body px-lg-5 py-lg-5">
            <div class="text-center text-muted mb-4">
                <h2>CONTATTI</h2>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-6 col-md-6 col-xs-12">
            <h3>Contatti Amministrazione</h3>
            <div class="card text-center">
                <div class="card-body">
                    <h5 class="card-title">Email
                        <a class="my-4" href="mailto:protocollo@comune.prezza.aq.it">protocollo@comune.prezza.aq.it</a>
                    </h5>
                    <h5 class="card-title">Telefono
                        <a class="my-4" href="tel:+39086445138">+39 0864 45138</a>
                    </h5>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
            <h3>Contatti sviluppatore</h3>
            <div class="card text-center">
                <div class="card-body">
                    <h5 class="card-title">Email
                        <a class="my-4" href="mailto:info@visitareprezza.it" >info@visitareprezza.it</a>
                    </h5>
                    <h5 class="card-title">Telefono
                        <a class="my-4" href="tel:+393920377589">+393920377589</a>
                    </h5>
                </div>
                <!-- <div class="card-body">
                    <h5 class="card-title">Info</h5>
                    <form role="form">
                        <div class="form-group">
                            <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ni ni-single-02"></i></span>
                                </div>
                                <input class="form-control" placeholder="Antonio" type="text">
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                                </div>
                                <input class="form-control" placeholder="Email" type="email">
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn btn-primary my-4">Invia</button>
                        </div>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>