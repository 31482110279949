import { Component, OnInit, OnDestroy } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services-common/common.service';
import { PostCategoryService } from 'src/app/services-common/post-category.service';
import { PostService } from 'src/app/services-common/post-service';
import { ProductAndServicesService } from 'src/app/services-common/product-and-services.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  public environment = environment;
  public productCategories!: any[];
  public categories!: any[];
//   // public images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  public images = [
    '2021/11/icon-roman-soldier.jpg', 
    '2021/11/icon-ruzzola.png', 
    '2021/11/icon-party.png', 
  ].map((n) => `assets/img/${n}`);
// // ].map((n) => `https://visitareprezza.it/engine/wp-content/uploads/${n}`);


  constructor(
    private titleService:Title,
    private productAndServicesService: ProductAndServicesService,
    private postService: PostService,
    private postCategoryService: PostCategoryService,
    private commonService: CommonService
  ) {
    this.titleService.setTitle(`${environment.appUrl} - Il terrazzo sulla valle peligna`);
  }

  async ngOnInit(): Promise<void> {
    this.commonService.AClicked(true);
    await this.initProductCategories();
    this.commonService.AClicked(false);
  }

  async initProductCategories(): Promise<void> {
    this.productCategories = await this.productAndServicesService.getProductsCategories();
    this.postCategoryService
      .findAll()
      .subscribe( data => {
        this.categories = data;
      });
    // console.warn(this.productCategories);
    
    // let productCategoriesEnabled: string[] = [];
    // this.productCategories.forEach((element, index)=>{
    //   if(element.slug !== "senza-categoria"){
    //     productCategoriesEnabled.push(element);
    //   }
    // });
    // this.productCategories = productCategoriesEnabled;

      // .then( data => {
      //   console.warn(data);
      // })
  }



  
  // getProduct(postId) :void{
  //   this.wcService
  //     .getProduct(postId)
  //     .subscribe( product => {
  //       this.products = product;
  //       console.log( product );
  //     });
  // }


  // getProducts() :void{
  //   this.wcService
  //     .getProducts()
  //     .subscribe( products => {
  //       this.products = products;
  //       console.log( products );
  //     });
  // }


  // getProductsCategories() :void{
  //   this.wcService
  //     .getProductsCategories()
  //     .subscribe( productCategories => {
  //       this.productCategories = productCategories;
  //       console.log( productCategories );
  //     });
  // }

 
  // getCategory(categoryId) :void{
  //   this.wcService
  //     .getProductCategory(categoryId)
  //     .subscribe( productCategory => {
  //       this.productCategory = productCategory;
  //       console.log( productCategory );
  //     });
  // }


  ngOnDestroy() {
  }

}
