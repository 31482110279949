import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { CommonService } from 'src/app/services-common/common.service';

@Component({
  selector: 'app-spinner-two',
  templateUrl: './spinner-two.component.html',
  styleUrls: ['./spinner-two.component.scss']
})
export class SpinnerTwoComponent implements OnInit {
  public isVisible: boolean = false;

  constructor(
    private commonService: CommonService
  ){}

  ngOnInit() {
    this.commonService
      .aClickedEvent
      .subscribe((data:boolean) => {
        this.isVisible = data;
        console.log('Event message from Component A: ' + data);
      });
  }

}
