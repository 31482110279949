import { Component } from '@angular/core';
import { Location } from '@angular/common'
import { Title } from '@angular/platform-browser';
import { PageInformationModel } from '../../models/page-information.model';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services-common/common.service';
import { FileService } from 'src/app/services-common/file.service';
import { TaxonomyService } from 'src/app/services-common/taxonomy.service';
import { environment } from 'src/environments/environment';
import { encode } from 'base64-arraybuffer';
import { PostService } from 'src/app/services-common/post-service';

@Component({
  selector: 'app-post-create',
  templateUrl: './post-create.component.html',
  styleUrls: ['./post-create.component.css']
})
export class PostCreateComponent {
  
  public product!: any;
  public productId!: string;
  public categories!: any[];
  
  public pageInformation: PageInformationModel = {
    title: "Inventory detail",
    subTitle: "Item N°: "
  };

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private location: Location,
    private postService: PostService,
    private commonService: CommonService,
    private taxonomyService: TaxonomyService,
    private fileService: FileService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(`${environment.appName}`);
    this.productId = this.route.snapshot.params['id'];
    if (this.productId) {
      // console.warn(this.route.snapshot.params['id']);
      await this.initProduct(this.productId);
    }

    this.buildTaxonomies();
  }

  async initProduct(id: string): Promise<void> {
    this.commonService.AClicked(true);
      this.postService
        .findOne(this.productId)
        .subscribe((data: any) => {
          this.product = data[0];
          // console.log(this.product);
          this.commonService.AClicked(false);
        });
  }

  buildTaxonomies() {
    this.taxonomyService.findAll('categories')
    .subscribe((data: any) => {
        // console.warn(data);
        this.categories = data;
    });
  }

  ngOnDestroy(): void {
    
  }

}
