<!--class pippo-->
<div class="card"
     [ngStyle]="{
          'background-image': (imgUrl === undefined) ? '' : 'url(' + imgUrl + ')',
          'background-position': 'center',
          'background-size': 'cover',
          'width': 100,
          'height': 'auto'
     }"
>
  <div [ngStyle]="{ 'background-color': '#fdfdfd8f', 'color': '#fff'}">
    <!--  <div class="card-header p-0 mx-3 mt-3 position-relative z-index-1">-->

    <!--  <div class="card-header p-0 position-relative z-index-1">-->
    <!--    <a href="" class="d-block">-->
    <!--      <img src="" class="img-fluid border-radius-lg">-->
    <!--    </a>-->
    <!--  </div>-->

    <div class="card-body pt-2">
      <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">House</span>
      <a href="" class="card-title h5 d-block text-darker">
        Shared Coworking
      </a>
      <p class="card-description mb-4">
        Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons.
      </p>
      <div class="author align-items-center">
        <img src="{{imgUrl}}" alt="..." class="avatar shadow">
        <div class="name ps-3">
          <span>Mathew Glock</span>
          <div class="stats">
            <small>Posted on 28 February</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
