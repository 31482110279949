import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventi-principali',
  templateUrl: './eventi-principali.component.html',
  styleUrls: ['./eventi-principali.component.scss']
})
export class EventiPrincipaliComponent implements OnInit {

  eventiPrincipali: any[] = [
    {
      src: "assets/img/website/icon-roman-soldier.jpg", 
      alt: "Rievocazione del Malmozetto",
      title: "RIEVOCAZIONE DEL MALMOZZETTO",
      content: `<strong>Cos è:</strong>  Rievocazione della Saga di Malmozzetto, dedicata alla leggenda del comandante normanno che, nel 1097, dopo aver messo ferro e fuoco l’intera Val Pescara, fu bloccato nel centro della valle Peligna da uno stratagemma della popolazione locale.<br />
      <strong>Come funziona:</strong> Invaghitosi della contessa Sansonesca, alla quale aveva proposto un accordo d'amore, viene attirato da lei con la promessa di suggellare quel patto amoroso ed imprigionato. Malmozzetto morirà solo, dimenticato da tutti.<br />
      <strong>Quando:</strong> Si svolge in agosto, durante l'estate prezzana.<br />`
    },
    {
      src: "assets/img/website/icon-ruzzola.png", 
      alt: "La Ruzzola",
      title: "LA RUZZOLA",
      content: `<strong>Cos è:</strong> Il lancio della ruzzola di Prezza è un evento storico del paese.<br />
      <strong>Come funziona:</strong> Il gioco è semplice: consiste nel completare un percorso all'interno del paese lanciando una forma di formaggio realizzata in legno. Vince chi raggiunge il traguardo effettuando meno tiri. Anticamente veniva utilizzata realmente una forma di formaggio e, in caso di rottura, veniva servita a tutti i compaesani.<br />
      <strong>Quando:</strong> Si svolge in agosto, ma il giorno preciso varia di anno in anno.<br />`
    },
    {
      src: "assets/img/website/icon-party.png", 
      alt: "Sagra Del Carciofo di Prezza",
      title: "SAGRA DEL CARCIOFO DI PREZZA",
      content: `<strong>Cos è:</strong> La sagra del carciofo di Prezza è una tra i più importanti eventi del paese.<br />
      <strong>Come funziona:</strong> Il carciofo di Prezza è un prodotto tipico molto importante e ricercato. Durante l'evento, che racconta la varietà particolare del carciofo di Prezza, sarà possibile degustare il prodotto principe, cucinato secondo le ricette locali. Verranno montati degli stand dove sedersi ed assaporare anche il famoso olio di Prezza<br />
      <strong>Quando:</strong> In genere si svolge a maggio, prima della partenza dell'estate prezzana.<br />`
    }
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
