import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, interval, take, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  // headers!: HttpHeaders;
  // http!: HttpClient;
    // headers: HttpHeaders = new HttpHeaders();

  // constructor(
  //   protected http: HttpClient
  // ) { }

  constructor(injector: Injector) {
    // this.headers = injector.get(HttpHeaders);
    // this.http = injector.get(HttpClient);
  }



    
  /* ************************ COMMON FUNCTIONS ************************ */
  // buildHeaders(): void {
  //   this.headers = new HttpHeaders();
  //   // this.headers.append( 'Authorization', 'Basic Y2tfZDk5YmRkZWM1ZTc3MzUxYjI5ODRjODAwYzBlNWM1YTU5MTZhYmQyYzpjc19jYjIyMzMwOTFjYjgzNWYyNjkyYzQ5ZDY2NTViNDgzNmVkOTNjZjJh' );
  //   this.headers = this.headers.append( 
  //     'Authorization', 
  //     'Basic Y2tfMjRjYjI2OGQxNzE2NGIzMWVlNzU2N2U3Yjk3Njc1MWYyMDE0NTY5Mzpjc19hOTU3ODk3ZDUxYzE2MDNkNDM4NmQzNzQ2ZGEyYjI4MTQ3ZjE2ZDEy' 
  //   );
  //   // console.log(this.headers);
  // }
}
