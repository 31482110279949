import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common'
import { Title } from '@angular/platform-browser';
import { FormGroup, FormControl } from '@angular/forms';
import { PageInformationModel } from '../../models/page-information.model';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services-common/common.service';
import { FileService } from 'src/app/services-common/file.service';
import { InventoryService } from 'src/app/services-common/inventory.service';
import { TaxonomyService } from 'src/app/services-common/taxonomy.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { encode } from 'base64-arraybuffer';
import { MessageService } from 'primeng/api';
import { ProductTypeEnum } from 'src/app/Enums/ProductTypeEnum';
import { ProductStatusEnum } from 'src/app/Enums/ProductStatusEnum';


@Component({
  selector: 'app-inventory-form',
  templateUrl: './inventory-form.component.html',
  styleUrls: ['./inventory-form.component.scss'],
  providers: [MessageService]
  // providers: [CommonService]
})
export class InventoryFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() productId!: any;
  @Input() product!: any;
  @Input() brands!: any[];
  @Input() zones!: any[];
  @Input() racks!: any[];
  @Input() locations!: any[];
  @Input() levels!: any[];
  @Input() productCategories!: any[];
  @Input() pageInformation!: PageInformationModel;

  status: "initial" | "uploading" | "success" | "fail" = "initial"; // Variable to store file status
  file: File | null = null; // Variable to store file
  fileCoded!: any;
  environment: any = environment;
  public productTypes!: string[];
  public productStatuses!: string[];

  inventoryForm = new FormGroup({
    _id: new FormControl(),
    user_id: new FormControl('1'),
    title: new FormControl(''),
    subTitle: new FormControl(''),
    slug: new FormControl(''),
    category: new FormControl(),
    brand: new FormControl(),
    zone: new FormControl(),
    rack: new FormControl(),
    location: new FormControl(),
    level: new FormControl(),
    short_description: new FormControl(''),
    type: new FormControl(''),
    status: new FormControl(''),
    price: new FormControl(''),
    regular_price: new FormControl(''),
    sale_price: new FormControl(''),
    quantity: new FormControl(''),
    featured_image: new FormControl(''),
    enabled: new FormControl(false),
    content: new FormControl(''),
    bookable: new FormControl(false),
    book_from: new FormControl(''),
    book_to: new FormControl(''),    
    phone: new FormControl(''),
    email: new FormControl(''),
    address: new FormControl(''),
    website: new FormControl(''),
    
  });




  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private location: Location,
    private inventoryService: InventoryService,
    private commonService: CommonService,
    private taxonomyService: TaxonomyService,
    private fileService: FileService,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.productTypes = this.getProductTypes();
    this.productStatuses = this.getProductStatuses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.product) {
      this.inventoryForm.patchValue({
        _id: this.productId,
        user_id: this.product.user_id,
        title: this.product.title,
        subTitle: this.product.subTitle,
        slug: this.product.slug,
        category: this.product.category?._id,
        brand: this.product.brand?._id,
        zone: this.product.zone?._id,
        rack: this.product.rack?._id,
        location: this.product.location?._id,
        level: this.product.level?._id,
        short_description: this.product.short_description,
        type: this.product.type,
        status: this.product.status,
        price: this.product.price,
        regular_price: this.product.regular_price,
        sale_price: this.product.sale_price,
        quantity: this.product.quantity,
        featured_image: this.product.featured_image,
        enabled: this.product.enabled,
        content: this.product.content,
        bookable: this.product.bookable,
        book_from: this.product.book_from,
        book_to: this.product.book_to,
        phone: this.product.phone,
        email: this.product.email,
        address: this.product.address,
        website: this.product.website,
      });
    }    
  }
  

  async initProduct(id: string): Promise<void> {
    this.commonService.AClicked(true);
      this.inventoryService
        .findOne(this.productId)
        .subscribe((data: any) => {
          this.product = data;
          console.log(this.product);
          this.inventoryForm.patchValue({
            _id: this.productId,
            user_id: this.product.user_id,
            title: this.product.title,
            subTitle: this.product.subTitle,
            slug: this.product.slug,
            category: this.product.category?._id,
            brand: this.product.brand?._id,
            zone: this.product.zone?._id,
            rack: this.product.rack?._id,
            location: this.product.location?._id,
            level: this.product.level?._id,
            short_description: this.product.short_description,
            type: this.product.type,
            status: this.product.status,
            price: this.product.price,
            regular_price: this.product.regular_price,
            sale_price: this.product.sale_price,
            quantity: this.product.quantity,
            featured_image: this.product.featured_image,
            enabled: this.product.enabled,
            content: this.product.content,
            bookable: this.product.bookable,
            book_from: this.product.book_from,
            book_to: this.product.book_to,
            phone: this.product.phone,
            email: this.product.email,
            address: this.product.address,
            website: this.product.website,
          });
          this.commonService.AClicked(false);
        });
  }

  back(){
    this.location.back;
  }


  buildPermalink() {
    if (this.inventoryForm.controls['title'].value) {
      let Text: string|null = this.inventoryForm.controls['title'].value;
      let TextSlugged       = this.convertToSlug(Text);
      this.inventoryForm.patchValue({
        slug: TextSlugged
      });
      console.warn(TextSlugged);
    }
  }

  convertToSlug(Text?: string) {
    if( Text ){
      return Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
    }
    return '';
  }

  checkAvailable($event: any){
    console.warn($event);
  }

  changeBookable($event: any){
    console.warn({
      event: $event,
      value: this.inventoryForm.controls['bookable'].value
    });
  }

  getProductTypes() {
    const productTypes = Object.keys(ProductTypeEnum).filter((item) => {
      return isNaN(Number(item));
    });
    // console.log(productTypes.join("\n"));
    
    return productTypes;
  }

  getProductStatuses() {
    const productStatuses = Object.keys(ProductStatusEnum).filter((item) => {
      return isNaN(Number(item));
    });
    // console.log(productStatuses.join("\n"));
    
    return productStatuses;
  }


  async uploadFile(event: any): Promise<any> {
    const file: File = event.target.files[0];
    // console.log(file);

    if (file) {
      this.status = "initial";
      this.file = file;
      this.fileCoded = await this.transformAttachments( event.target.files, {
        folder      : this.inventoryForm.controls['slug'].value,
        entityType  : this.inventoryForm.controls['type'].value?.toLowerCase(),
      } );
      this.inventoryForm.patchValue({featured_image: this.fileCoded[0].name})
      console.warn(this.fileCoded);
    }
  }

  async transformAttachments( files: any, productData: any ){
      let filesTransformed: any = [];

      for (let index = 0; index < files.length; index++) {
          let item = files[index];
          let fileContent = await this.getBase64EncodedFileData( item );
          let file = {
              name      : item.name,
              tipology  : item.type,
              type      : item.name.split('.').pop(),
              data      : fileContent,
              folder    : productData.folder,
              entityType: productData.entityType
          };
          filesTransformed.push( file );
      }
      return filesTransformed;
  }

  public getBase64EncodedFileData(file: File): Promise<any> {
      return new Observable(observer => {
          const reader = new FileReader();

          reader.onload = (): void => {
              const {result} = reader;
              const data = result as ArrayBuffer;
              const base64Encoded = encode(data);

              observer.next(base64Encoded);
              observer.complete();
          };

          reader.onerror = (): void => {
              observer.error(reader.error);
          };

          reader.readAsArrayBuffer(file);
      }).toPromise();
  }


  async update(): Promise<void> {
    this.commonService.AClicked(true);
    console.log(this.inventoryForm.value);
    // console.log(this.productId);
    // await this.fileService.create(this.fileCoded);
    this.inventoryService
      .updateOne(this.productId, this.inventoryForm.value)
      .subscribe(async (data: any) => {
        console.warn(data);
        await this.initProduct(this.productId);
        this.commonService.AClicked(false);
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update success' });
        this.commonService.sendMessage({ severity: 'success', summary: 'Success', detail: 'Update success' });
      });
  }

  async save(): Promise<void> {
    this.commonService.AClicked(true);
    console.log({
      file: this.fileCoded,
      inventoryForm: this.inventoryForm.value
    });
    await this.fileService.create(this.fileCoded);
    this.inventoryService
      .create(this.inventoryForm.value)
      .subscribe((data: any) => {
        console.warn(data);
        this.commonService.AClicked(false);
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Save success' });
        this.commonService.sendMessage({ severity: 'success', summary: 'Success', detail: 'Save success' });
      });
  }

  ngOnDestroy(): void {
  }

}
