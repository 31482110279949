import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, interval, take, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MoneyManagerService {

  path: string = `${environment.apiBasePath}`;
  transactionRoute: string = '/money-transactions';
  accountRoute: string = '/money-accounts';
  categoryRoute: string = '/money-categories';
  headers!: HttpHeaders;


  constructor(
    protected http: HttpClient
  ) {
    this.buildHeaders();
  }

  createTransaction(body: any): Observable<any> {
    return this.http.post<any>( `${this.path}${this.transactionRoute}`, body, { headers: this.headers});
  }

  findAllTransactions(): Observable<any> {
    return this.http.get<any>( `${this.path}${this.transactionRoute}`, { headers: this.headers});
  }

  findOneTransaction(id: string): Observable<any> {
    return this.http.get<any>( `${this.path}${this.transactionRoute}/${id}`, { headers: this.headers});
  }

  createAccount(body: any): Observable<any> {
    return this.http.post<any>( `${this.path}${this.accountRoute}`, body, { headers: this.headers});
  }

  findAllAccounts(): Observable<any> {
    return this.http.get<any>( `${this.path}${this.accountRoute}`, { headers: this.headers});
  }

  findOneAccount(id: string): Observable<any> {
    return this.http.get<any>( `${this.path}${this.accountRoute}/${id}`, { headers: this.headers});
  }

  createCategory(body: any): Observable<any> {
    return this.http.post<any>( `${this.path}${this.categoryRoute}`, body, { headers: this.headers});
  }

  findAllCategories(): Observable<any> {
    return this.http.get<any>( `${this.path}${this.categoryRoute}`, { headers: this.headers});
  }

  findOneCategory(id: string): Observable<any> {
    return this.http.get<any>( `${this.path}${this.categoryRoute}/${id}`, { headers: this.headers});
  }
  


  buildHeaders(): void {
    this.headers = new HttpHeaders();
    // this.headers.append( 'Authorization', 'Basic Y2tfZDk5YmRkZWM1ZTc3MzUxYjI5ODRjODAwYzBlNWM1YTU5MTZhYmQyYzpjc19jYjIyMzMwOTFjYjgzNWYyNjkyYzQ5ZDY2NTViNDgzNmVkOTNjZjJh' );
    this.headers = this.headers.append( 
      'Authorization', 
      'Basic Y2tfMjRjYjI2OGQxNzE2NGIzMWVlNzU2N2U3Yjk3Njc1MWYyMDE0NTY5Mzpjc19hOTU3ODk3ZDUxYzE2MDNkNDM4NmQzNzQ2ZGEyYjI4MTQ3ZjE2ZDEy' 
    );
    // console.log(this.headers);
  }

}
