import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/services-common/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inventory-info-card',
  templateUrl: './inventory-info-card.component.html',
  styleUrls: ['./inventory-info-card.component.scss']
})
export class InventoryInfoCardComponent implements OnInit, OnDestroy {

  @Input() item!: any;
  @Input() baseRoute!: any;
  @Input() type!: any;
  
  public environment = environment;

  constructor(
    private commonService: CommonService
  ) {
  }

  ngOnInit() {
    // console.warn(this.item);
  }

  public goToDetail(slug: string): void{
    alert(slug);
  }

  public async addToCart(item: any) {
    // console.warn(item);
    this.commonService.AClicked(true);
    setTimeout( async () => {
      // alert(`Item n°: ${item.title} add to cart!`);
      this.commonService.addToCart(item);
      this.commonService.AClicked(false);
      this.commonService.sendMessage({ severity: 'info', summary: '', detail: `${item.title} added to cart` });
    },900);
  }

  ngOnDestroy(): void {
    
  }

}
