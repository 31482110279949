import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/services-common/common.service';
import { TaxonomyService } from 'src/app/services-common/taxonomy.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-taxonomies',
  templateUrl: './taxonomies.component.html',
  styleUrls: ['./taxonomies.component.scss']
})
export class TaxonomiesComponent implements OnInit {

  public taxonomies!: any[];
  public type!: string|null;
  public baseTaxonomyCardRoute!: string;


  constructor(
    private titleService:Title,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private messageService: MessageService,
    private taxonomyService: TaxonomyService,
  ) { }


  ngOnInit(): void {
    // this.taxonomy = this.route.snapshot.params['taxonomy']; // OK BUT STATIC APPROACH
    this.titleService.setTitle(`${environment.appName}`);
    
    this.route.paramMap.subscribe(params => {
      this.type = params.get('taxonomy');
      console.log(this.type);

      if (this.type) {
        this.taxonomyService.findAll( this.type )
        .subscribe((data: any) => {
            console.warn(data);
            this.taxonomies = data;
        });

        this.baseTaxonomyCardRoute = `/admin/taxonomies/${this.type}`;
      }
    });


    // this.productId = this.route.snapshot.params['id'];
    // if (this.productId) {
    //   // console.warn(this.route.snapshot.params['id']);
    //   await this.initProduct(this.productId);
    // }
    // console.warn( this.route.snapshot.params['slug'] );
  }

  add() {
    this.router.navigate(['/', 'admin', 'taxonomies', this.type, 'create']);
  }

}
