<div class="row justify-content-center pt-5">
    <!-- <div class="card-body px-lg-5 pt-5"> -->
    <div class="card-body">
      <div class="text-center text-muted mb-4">
        <h2>EVENTI PRINCIPALI</h2>
      </div>
    </div>
</div>

<div class="row justify-content-center">
  <div class="col-lg-4 col-md-12 col-xs-12 mb-3 mb-sm-3 mb-md-3 mb-lg-0" *ngFor="let evento of eventiPrincipali">
    <div class="card">
      <img class="card-img-top" 
        src="{{evento.src}}" 
        alt="{{evento.alt}}"
        style="width:200px;
        margin: 0 auto;
        padding-top: 20px;"
      >
      <div class="card-body">
        <h5 class="card-title">{{evento.title}}</h5>
        <p class="card-text" [innerHTML]="evento.content">
        </p>
        <!-- <a href="javascript:;" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
  </div>
</div>