import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services-common/common.service';
import { InventoryService } from 'src/app/services-common/inventory.service';
import { MoneyManagerService } from 'src/app/services-common/money-manager.service';
import { environment } from 'src/environments/environment';
import { TransactionTypeEnum } from 'src/app/Enums/TransactionTypeEnum';
import { SlugUtils } from 'src/app/utils-common/SlugUtils';


@Component({
  selector: 'app-money-accounts',
  templateUrl: './money-accounts.component.html',
  styleUrls: ['./money-accounts.component.scss']
})
export class MoneyAccountsComponent implements OnInit, OnDestroy {
  public enableDestination!: boolean;
  public accounts!: any;
  public accountForm = new FormGroup({
    user_id: new FormControl('1'),
    name: new FormControl(''),
    slug: new FormControl(''),
    fund: new FormControl(''),
    color: new FormControl(''),
    content: new FormControl(''),
  });
  public transactionTypes: string[] = [
    TransactionTypeEnum.EXPENSE,
    TransactionTypeEnum.INCOME,
    TransactionTypeEnum.TRANSFER
  ];

  constructor(
    private titleService:Title,
    // private location: Location,
    private commonService: CommonService,
    private moneyManagerService: MoneyManagerService,
    private modalService: NgbModal    
  ) { }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(`${environment.appName}`);
    await this.init();
  }


  async init(): Promise<void> {
    this.commonService.AClicked(true);
    this.moneyManagerService
      .findAllAccounts()
      .subscribe((data: any) => {
        // console.warn(data);
        this.accounts = data;
        // console.log(this.accounts);
        this.commonService.AClicked(false);
      });
  }


  search(){
    alert('mimmo');
  }

  openXl(content: TemplateRef<any>) {
		this.modalService.open(content, { size: 'xl' });
	}

  modalClose() {
    // modal.close('Close click')
    this.modalService.dismissAll();
    this.accountForm.reset();
  }

  open(content: TemplateRef<any>, $event: any) {
    this.accountForm.patchValue(
      $event
    );
    this.modalService.open(content, { size: 'xl' });
  }

  enableDestinationFound(value: string) {
    console.warn(value);
    if(value === 'TRANSFER'){
      this.enableDestination = true;
    }else {
      this.enableDestination = false;
    }
  }

  buildPermalink() {
    if (this.accountForm.controls['name'].value) {
      let Text: string|null = this.accountForm.controls['name'].value;
      let TextSlugged       = SlugUtils.convertToSlug(Text);
      this.accountForm.patchValue({
        slug: TextSlugged
      });
      console.warn(TextSlugged);
    }
  }

  saveTransaction() {
    console.warn(this.accountForm.value);
    this.moneyManagerService.createAccount(this.accountForm.value)
      .subscribe((data: any) => {

      });
    this.modalService.dismissAll();
    this.accountForm.reset();
    this.init();
  }

  ngOnDestroy(): void {
    
  }

}
