import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';
import { FrontLayoutModule } from './layouts/front-layout/front-layout.module';
import { ComponentsCommonModule } from './components-common/components-common.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AccordionStaticComponent } from './components-front/accordion-static/accordion-static.component';
import { AppComponent } from './app.component';
import { TaxonomiesComponent } from './pages-common/taxonomies/taxonomies.component';
import { TaxonomiesCreateComponent } from './pages-common/taxonomies-create/taxonomies-create.component';
import { BaseComponent } from './pages-common/base/base.component';
import { MessageService } from 'primeng/api';
import { ComponentsAdminModule } from "./components-admin/components-admin.module";
import { EditorModule } from '@tinymce/tinymce-angular';
import { LoginComponent } from './pages-common/login/login.component';
import { ToastModule } from 'primeng/toast';

@NgModule({
    declarations: [
        AppComponent,
        AccordionStaticComponent,
        TaxonomiesComponent,
        TaxonomiesCreateComponent,
        BaseComponent,
        LoginComponent,
        // BackofficeComponent,
        // CategoriesComponent,
        // CategoryComponent,
        // FrontofficeComponent,
        // IndexComponent,
        // ItemComponent,
    ],
    providers: [MessageService],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        ReactiveFormsModule,
        AdminLayoutModule,
        FrontLayoutModule,
        ComponentsCommonModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        NgbModule,
        ComponentsAdminModule,
        EditorModule,
        ToastModule
    ]
})
export class AppModule { }

