import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, interval, take, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WCBaseCategory } from 'src/app/models/wc-base-category-model';
import { WCProduct } from '../models/wc-product';

@Injectable({
  providedIn: 'root'
})
export class WareHouseService {

  path: string = `${environment.wordPressBasePath}${environment.wordPressRestpath}${environment.wordpressCustomTaxonomyRestPath}${environment.wooCommerceRestVersion}`;
  headers!: HttpHeaders;
  taxonomiesPath: string = `/taxonomies`;
  termsPath: string = `/terms`;

  
  constructor(
    private http: HttpClient
  ) {
    this.buildHeaders();
  }

  getRelatedTerms(): Promise<any> {
    const relatedTerms = firstValueFrom(
      this.http.
        get<any>(`${this.path}${this.taxonomiesPath}${this.termsPath}`, {headers: this.headers})
        .pipe(
          map(theRelatedTerms => {
            return theRelatedTerms;
          })
        )
    );
    return relatedTerms;
  }

  getRelatedTermsByPost(postId: number): Promise<any> {
    const relatedTerms = firstValueFrom(
      this.http
        .get<any>(`${this.path}${this.taxonomiesPath}${this.termsPath}/${postId}`, {headers: this.headers})
        .pipe(
          map(theRelatedTerms => {
            return theRelatedTerms;
          })
        )
    );
    return relatedTerms;
  }


  /* ************************ COMMON FUNCTIONS ************************ */
  buildHeaders(): void {
    this.headers = new HttpHeaders();
    // this.headers.append( 'Authorization', 'Basic Y2tfZDk5YmRkZWM1ZTc3MzUxYjI5ODRjODAwYzBlNWM1YTU5MTZhYmQyYzpjc19jYjIyMzMwOTFjYjgzNWYyNjkyYzQ5ZDY2NTViNDgzNmVkOTNjZjJh' );
    this.headers = this.headers.append( 
      'Authorization', 
      'Basic Y2tfMjRjYjI2OGQxNzE2NGIzMWVlNzU2N2U3Yjk3Njc1MWYyMDE0NTY5Mzpjc19hOTU3ODk3ZDUxYzE2MDNkNDM4NmQzNzQ2ZGEyYjI4MTQ3ZjE2ZDEy' 
    );
    // console.log(this.headers);
  }
}
