import { Component, OnDestroy, OnInit } from '@angular/core';
import { IMenuItem } from 'src/app/interfaces/MenuItem';
import { CommonService } from 'src/app/services-common/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy{

  public sidebarOpened!: boolean;
  public appTitle: string = environment.appTitle;
  public menuItems: IMenuItem[] = [
    {
      name: "Products", 
      url: "/admin/inventory", 
      icon: "<i class=\"ni ni-calendar-grid-58 text-warning text-sm opacity-10\"></i>",
      withDivider: false,
    }, 
    {
      name: "Product Categories", 
      url: "/admin/taxonomies/product-categories", 
      icon: "<i class=\"ni ni-calendar-grid-58 text-warning text-sm opacity-10\"></i>",
      withDivider: false,
    }, 
    {
      name: "Racks", 
      url: "/admin/taxonomies/racks",
      icon: "<i class=\"ni ni-calendar-grid-58 text-warning text-sm opacity-10\"></i>",
      withDivider: false,
    }, 
    {
      name: "Livelli", 
      url: "/admin/taxonomies/levels", 
      icon: "<i class=\"ni ni-calendar-grid-58 text-warning text-sm opacity-10\"></i>",
      withDivider: false,
    }, 
    {
      name: "Brand", 
      url: "/admin/taxonomies/brands", 
      icon: "<i class=\"ni ni-calendar-grid-58 text-warning text-sm opacity-10\"></i>",
      withDivider: false,
    }, 
    {
      name: "Zone", 
      url: "/admin/taxonomies/zones", 
      icon: "<i class=\"ni ni-calendar-grid-58 text-warning text-sm opacity-10\"></i>",
      withDivider: false,
    }, 
    {
      name: "Location", 
      url: "/admin/taxonomies/locations", 
      icon: "<i class=\"ni ni-calendar-grid-58 text-warning text-sm opacity-10\"></i>",
      withDivider: true,
    }, 
    {
      name: "Money Transactions", 
      url: "/admin/money-manager", 
      icon: "<i class=\"ni ni-money-coins text-info text-sm opacity-10\"></i>",
      withDivider: false,
    },
    {
      name: "Money Categories", 
      url: "/admin/money-categories", 
      icon: "<i class=\"ni ni-money-coins text-info text-sm opacity-10\"></i>",
      withDivider: false,
    },
    {
      name: "Money Accounts", 
      url: "/admin/money-accounts", 
      icon: "<i class=\"ni ni-money-coins text-info text-sm opacity-10\"></i>",
      withDivider: true,
    },

    {
      name: "Posts", 
      url: "/admin/posts", 
      icon: "<i class=\"ni ni-calendar-grid-58 text-warning text-sm opacity-10\"></i>",
      withDivider: false,
    }, 
    {
      name: "Categories", 
      url: "/admin/taxonomies/categories", 
      icon: "<i class=\"ni ni-calendar-grid-58 text-warning text-sm opacity-10\"></i>",
      withDivider: true,
    }, 
  ];

  constructor(
    private commonService: CommonService
  ){}

  ngOnInit(): void {
    this.commonService
    .sidebarToggleEvent
    .subscribe((data:boolean) => {
      this.sidebarOpened = data;
      // console.log(` ora entraaaa?: ${!data}`);
      console.log(this.sidebarOpened);
    });
  }

  onToggle() {
    // console.warn(this.state);
    this.commonService.sidebarOpened = !this.commonService.sidebarOpened;
    this.commonService.sidebarToggle(this.commonService.sidebarOpened);
    // console.warn(this.state);
  }

  ngOnDestroy(): void {
    
  }


}
