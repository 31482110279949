import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, interval, take, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  path: string = `${environment.apiBasePath}`;
  headers!: HttpHeaders;

  constructor(
    protected http: HttpClient
  ) {
    this.buildHeaders();
  }

  login(body: any): Observable<any> {
    return this.http.post<any>( `${this.path}/auth/login`, body, { headers: this.headers});
  }

  logout(): Observable<any> {
    return this.http.get<any>( `${this.path}/auth/logout`, { headers: this.headers});
  }

  profile(id: string): Observable<any> {
    return this.http.get<any>( `${this.path}/auth/profile`, { headers: this.headers});
  }
  
  public isAuthenticated() : boolean {
    const token = localStorage.getItem(`${environment.prefix}_token`);
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(token);
    return !isExpired;
  }

  buildHeaders(): void {
    this.headers = new HttpHeaders();
    // this.headers.append( 'Authorization', 'Basic Y2tfZDk5YmRkZWM1ZTc3MzUxYjI5ODRjODAwYzBlNWM1YTU5MTZhYmQyYzpjc19jYjIyMzMwOTFjYjgzNWYyNjkyYzQ5ZDY2NTViNDgzNmVkOTNjZjJh' );
    this.headers = this.headers.append( 
      'Authorization', 
      'Basic Y2tfMjRjYjI2OGQxNzE2NGIzMWVlNzU2N2U3Yjk3Njc1MWYyMDE0NTY5Mzpjc19hOTU3ODk3ZDUxYzE2MDNkNDM4NmQzNzQ2ZGEyYjI4MTQ3ZjE2ZDEy' 
    );
    // console.log(this.headers);
  }
}
