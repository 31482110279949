import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HeroComponent} from './hero/hero.component';
import {FooterComponent} from './footer/footer.component';
// import {SidebarComponent} from './sidebar/sidebar.component';
// import {NavbarComponent} from './navbar/navbar.component';
// import {FooterComponent} from './footer/footer.component';
// import {HeroComponent} from './hero/hero.component';
// import { RisultatiComponent } from './risultati/risultati.component';
// import { RicercaComponent } from './ricerca/ricerca.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ],
  declarations: [
    HeroComponent,
    FooterComponent,
    // FooterComponent,
    // NavbarComponent,
    // SidebarComponent,
    // HeroComponent,
    // MenuComponent,
    // RisultatiComponent,
    // RicercaComponent
  ],
  exports: [
    HeroComponent,
    FooterComponent,
    // FooterComponent,
    // NavbarComponent,
    // SidebarComponent,
    // HeroComponent,
    // MenuComponent,
    // RicercaComponent
  ]
})
export class ComponentsFrontModule {}
