import { Injectable, EventEmitter, Output } from '@angular/core';
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  @Output() aClickedEvent = new EventEmitter<boolean>();
  @Output() sidebarToggleEvent = new EventEmitter<boolean>();
  @Output() addToCartEvent = new EventEmitter<Object>();
  @Output() addToastMessageEvent = new EventEmitter<Object>();
  public sidebarOpened: boolean = false;

  constructor(
    private messageService: MessageService
  ) { }

  AClicked(state: boolean) {
    this.aClickedEvent.emit(state);
  }

  sidebarToggle(state: boolean) {
    this.sidebarToggleEvent.emit(state);
  }

  addToCart(data: Object) {
    // console.warn(data);
    this.addToCartEvent.emit(data);
  }

  sendMessage(data: Object){
    this.addToastMessageEvent.emit(data);
    this.messageService.add(data);

  }
}
