import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard';
import { PostCreateComponent } from 'src/app/pages-admin/post-create/post-create.component';
import { PostComponent } from 'src/app/pages-admin/post/post.component';
import { DashboardComponent } from 'src/app/pages-common/dashboard/dashboard.component';
import { InventoryDetailComponent } from 'src/app/pages-common/inventory-detail/inventory-detail.component';
import { InventoryComponent } from 'src/app/pages-common/inventory/inventory.component';
import { MoneyAccountsComponent } from 'src/app/pages-common/money-accounts/money-accounts.component';
import { MoneyCategoriesComponent } from 'src/app/pages-common/money-categories/money-categories.component';
import { MoneyManagerComponent } from 'src/app/pages-common/money-manager/money-manager.component';
import { TaxonomiesCreateComponent } from 'src/app/pages-common/taxonomies-create/taxonomies-create.component';
import { TaxonomiesComponent } from 'src/app/pages-common/taxonomies/taxonomies.component';
import { TestComponent } from 'src/app/pages-common/test/test.component';


export const AdminLayoutRoutes: Routes = [
  { path: '',                             component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard',                    component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'inventory',                    component: InventoryComponent, canActivate: [AuthGuard] },
  { path: 'inventory/create',             component: InventoryDetailComponent, canActivate: [AuthGuard] },
  { path: 'inventory/:id',                component: InventoryDetailComponent, canActivate: [AuthGuard] },
  { path: 'taxonomies/:taxonomy',         component: TaxonomiesComponent, canActivate: [AuthGuard] },
  { path: 'taxonomies/:taxonomy/create',  component: TaxonomiesCreateComponent, canActivate: [AuthGuard] },
  { path: 'taxonomies/:taxonomy/:id',     component: TaxonomiesCreateComponent, canActivate: [AuthGuard] },
  { path: 'money-manager',                component: MoneyManagerComponent, canActivate: [AuthGuard] },
  // { path: 'money-manager/:id',            component: MoneyManagerComponent },
  { path: 'money-accounts',               component: MoneyAccountsComponent, canActivate: [AuthGuard] },
  { path: 'money-categories',             component: MoneyCategoriesComponent, canActivate: [AuthGuard] },
  { path: 'posts',                        component: PostComponent, canActivate: [AuthGuard] },
  { path: 'posts/create',                 component: PostCreateComponent, canActivate: [AuthGuard] },
  { path: 'posts/:id',                    component: PostCreateComponent, canActivate: [AuthGuard] },
  { path: 'test',                         component: TestComponent },
  // { path: 'clienti',      component: ClientiComponent },
  // { path: 'servizi',      component: ServiziComponent },
  // { path: 'user-profile', component: UserProfileComponent },
  // { path: 'tables',       component: TablesComponent },
  // { path: 'icons',        component: IconsComponent },
  // { path: 'maps',         component: MapsComponent }
];
// const routes: Routes = [
//   { path: '', component: IndexComponent },
//   { path: 'index', component: IndexComponent },
//   // { path: 'categories', component: CategoriesComponent },
//   { 
//     path: 'admin', 
//     component: BackofficeComponent,
//     children: [
//       { path: 'dashboard', component: DashboardComponent },
//       // { path: 'taxonomy/{taxonomy}', component: InventoryCreateComponent },
//     ]
//   },
//   // { path: 'inventory', component: InventoryComponent },
//   // { path: 'inventory/:id', component: ItemComponent },
//   { path: 'item', component: ItemComponent },
//   {
//     path: 'home',
//     component: FrontofficeComponent,
//     children: [
//       // { path: '', component: DashboardComponent },
//       { path: 'categories', component: CategoriesComponent },
//     ]
//   },
//   {
//     path: 'categories/:slug',
//     component: FrontofficeComponent,
//     children: [
//       { path: '', component: CategoryComponent },
//     ]
//   }
// ];


@NgModule({
  imports: [RouterModule.forChild(AdminLayoutRoutes)],
  exports: [RouterModule]
})
export class AdminLayoutRoutingModule { }
