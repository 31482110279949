
import { NgModule } from '@angular/core';
import { NgbModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { SpinnerOneComponent } from './spinner-one/spinner-one.component';
import { SpinnerTwoComponent } from './spinner-two/spinner-two.component';
import { TimelineOneComponent } from './timeline-one/timeline-one.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgbTooltipModule, 
    // ToastsContainer,
    // ToastComponent,
  ],
  declarations: [
    MenuComponent,
    SpinnerOneComponent,
    SpinnerTwoComponent,
    TimelineOneComponent,
  ],
  exports: [
    MenuComponent,
    SpinnerOneComponent,
    SpinnerTwoComponent,
    TimelineOneComponent,
  ]
})
export class ComponentsCommonModule {}
