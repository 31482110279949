import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PageInformationModel } from 'src/app/models/page-information.model';
import { SlugUtils } from 'src/app/utils-common/SlugUtils';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent {

  public pageInformation!: PageInformationModel;

  constructor() {
  }

  buildPageInformation(date: string): PageInformationModel {
    return {
      title: `New ${date}`,
      subTitle: `Create new ${date}`
    }
  }

  buildPermalink( form: FormGroup, field: string ) {
    // if (this.form.controls['title'].value) {
    //   let Text: string|null = this.form.controls['title'].value;
    //   let TextSlugged       = SlugUtils.convertToSlug(Text);
    //   this.form.patchValue({
    //     slug: TextSlugged
    //   });
    //   console.warn(TextSlugged);
    // }
    if (form.controls[field].value) {
      let Text: string = form.controls[field].value;
      let TextSlugged       = SlugUtils.convertToSlug(Text);
      form.patchValue({
        slug: TextSlugged
      });
      console.warn(TextSlugged);
    }
  }

}
