import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CategoriaProdottoComponent } from 'src/app/pages/categoria.prodotto/categoria.prodotto.component';
import { ContattiComponent } from 'src/app/pages/contatti/contatti.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { ProductDetailComponent } from 'src/app/pages/product-detail/product-detail.component';
import { StoriaComponent } from 'src/app/pages/storia/storia.component';


export const FrontLayoutRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'storia', component: StoriaComponent },
  { path: 'contatti', component: ContattiComponent },
  { path: 'categorie/:slug', component: CategoriaProdottoComponent },
  { path: 'categorie/:routeSlug/:slug', component: ProductDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(FrontLayoutRoutes)],
  exports: [RouterModule]
})
export class FrontLayoutRoutingModule { }
