import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common'
import { Title } from '@angular/platform-browser';
import { FormGroup, FormControl } from '@angular/forms';
import { PageInformationModel } from '../../models/page-information.model';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services-common/common.service';
import { FileService } from 'src/app/services-common/file.service';
import { InventoryService } from 'src/app/services-common/inventory.service';
import { TaxonomyService } from 'src/app/services-common/taxonomy.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { encode } from 'base64-arraybuffer';
import { MessageService } from 'primeng/api';
import { PostService } from 'src/app/services-common/post-service';
import { PostStatusEnum } from 'src/app/Enums/PostStatusEnum';
import { PostTypeEnum } from 'src/app/Enums/PostTypeEnum';



@Component({
  selector: 'app-post-form',
  templateUrl: './post-form.component.html',
  styleUrls: ['./post-form.component.css'],
  providers: [MessageService]
})
export class PostFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() productId!: any;
  @Input() product!: any;
  @Input() categories!: any[];
  @Input() pageInformation!: PageInformationModel;

  status: "initial" | "uploading" | "success" | "fail" = "initial"; // Variable to store file status
  file: File | null = null; // Variable to store file
  fileCoded!: any;
  environment: any = environment;
  public postTypes!: string[];
  public postStatuses!: string[];

  form = new FormGroup({
    _id: new FormControl(),
    user_id: new FormControl('1'),
    title: new FormControl(''),
    subTitle: new FormControl(''),
    slug: new FormControl(''),
    category: new FormControl(),
    short_description: new FormControl(),
    type: new FormControl(''),
    status: new FormControl(''),
    content: new FormControl(),
    featured_image: new FormControl(''),
    enabled: new FormControl(false),
  });




  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private location: Location,
    private postService: PostService,
    private commonService: CommonService,
    private taxonomyService: TaxonomyService,
    private fileService: FileService,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.postTypes = this.getPostTypes();
    this.postStatuses = this.getPostStatuses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.product) {
      this.form.patchValue({
        _id: this.productId,
        user_id: this.product.user_id,
        title: this.product.title,
        subTitle: this.product.subTitle,
        slug: this.product.slug,
        category: this.product.category?._id,
        short_description: this.product.short_description,
        type: this.product.type,
        status: this.product.status,
        content: this.product.content,
        featured_image: this.product.featured_image,
        enabled: this.product.enabled,
      });
    }    
  }
  

  async initProduct(id: string): Promise<void> {
    this.commonService.AClicked(true);
      this.postService
        .findOne(this.productId)
        .subscribe((data: any) => {
          this.product = data;
          console.log(this.product);
          this.form.patchValue({
            _id: this.productId,
            user_id: this.product.user_id,
            title: this.product.title,
            subTitle: this.product.subTitle,
            slug: this.product.slug,
            category: this.product.category?._id,
            short_description: this.product.short_description,
            type: this.product.type,
            status: this.product.status,
            content: this.product.content,
            featured_image: this.product.featured_image,
            enabled: this.product.enabled,
          });
          this.commonService.AClicked(false);
        });
  }

  back(){
    this.location.back;
  }


  buildPermalink() {
    if (this.form.controls['title'].value) {
      let Text: string|null = this.form.controls['title'].value;
      let TextSlugged       = this.convertToSlug(Text);
      this.form.patchValue({
        slug: TextSlugged
      });
      console.warn(TextSlugged);
    }
  }

  convertToSlug(Text?: string) {
    if( Text ){
      return Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
    }
    return '';
  }

  checkAvailable($event: any){
    console.warn($event);
  }

  getPostTypes() {
    const postTypes = Object.keys(PostTypeEnum).filter((item) => {
      return isNaN(Number(item));
    });
    // console.log(productTypes.join("\n"));
    
    return postTypes;
  }

  getPostStatuses() {
    const postStatuses = Object.keys(PostStatusEnum).filter((item) => {
      return isNaN(Number(item));
    });
    // console.log(productStatuses.join("\n"));
    
    return postStatuses;
  }

  async uploadFile(event: any): Promise<any> {
    const file: File = event.target.files[0];
    // console.log(file);

    if (file) {
      this.status = "initial";
      this.file = file;
      this.fileCoded = await this.transformAttachments( event.target.files );
      this.form.patchValue({featured_image: this.fileCoded[0].name})
      console.warn(this.fileCoded);
    }
  }

  async transformAttachments( files: any ){
      let filesTransformed: any = [];

      for (let index = 0; index < files.length; index++) {
          let item = files[index];
          let fileContent = await this.getBase64EncodedFileData( item );
          let file = {
              name    : item.name,
              tipology   : item.type,
              type    : item.name.split('.').pop(),
              data        : fileContent
          };
          filesTransformed.push( file );
      }
      return filesTransformed;
  }

  public getBase64EncodedFileData(file: File): Promise<any> {
      return new Observable(observer => {
          const reader = new FileReader();

          reader.onload = (): void => {
              const {result} = reader;
              const data = result as ArrayBuffer;
              const base64Encoded = encode(data);

              observer.next(base64Encoded);
              observer.complete();
          };

          reader.onerror = (): void => {
              observer.error(reader.error);
          };

          reader.readAsArrayBuffer(file);
      }).toPromise();
  }


  async update(): Promise<void> {
    this.commonService.AClicked(true);
    console.log(this.form.value);
    // console.log(this.productId);
    // await this.fileService.create(this.fileCoded);
    this.postService
      .updateOne(this.productId, this.form.value)
      .subscribe(async (data: any) => {
        console.warn(data);
        await this.initProduct(this.productId);
        this.commonService.AClicked(false);
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update success' });
        this.commonService.sendMessage({ severity: 'success', summary: 'Success', detail: 'Update success' });
      });
  }

  async save(): Promise<void> {
    this.commonService.AClicked(true);
    console.log(this.form.value);
    await this.fileService.create(this.fileCoded);
    this.postService
      .create(this.form.value)
      .subscribe((data: any) => {
        console.warn(data);
        this.commonService.AClicked(false);
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Save success' });
        this.commonService.sendMessage({ severity: 'success', summary: 'Success', detail: 'Save success' });
      });
  }

  ngOnDestroy(): void {
  }

}
