import { Component } from "@angular/core";


export enum TaxonomyEnum {
    BRANDS              = 'brands',
    CATEGORIES          = 'categories',
    ZONES               = 'zones',
    RACKS               = 'racks',
    LOCATIONS           = 'locations',
    LEVELS              = 'levels',

    PRODUCT_CATEGORIES  = "product-categories"
}