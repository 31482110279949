<app-spinner-one></app-spinner-one>
<div class="min-height-300 bg-primary position-absolute w-100"></div>
<app-sidebar></app-sidebar>

<main class="main-content position-relative border-radius-lg ps sidenav-fixed-start-main-content">
<!-- <main class="main-content position-relative border-radius-lg ps"> -->
      <app-navbar></app-navbar>
      <router-outlet></router-outlet>
</main>

<p-toast></p-toast>