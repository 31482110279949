import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsCommonModule } from 'src/app/components-common/components-common.module';
import { AdminLayoutRoutes, AdminLayoutRoutingModule } from './admin-layout-routing.module';
import { AdminLayoutComponent } from './admin-layout.component';
import { DashboardComponent } from 'src/app/pages-common/dashboard/dashboard.component';
import { InventoryComponent } from 'src/app/pages-common/inventory/inventory.component';
import { ItemComponent } from 'src/app/pages-common/item/item.component';
import { ComponentsAdminModule } from 'src/app/components-admin/components-admin.module';
import { InventoryDetailComponent } from 'src/app/pages-common/inventory-detail/inventory-detail.component';
import { MoneyAccountsComponent } from 'src/app/pages-common/money-accounts/money-accounts.component';
import { MoneyCategoriesComponent } from 'src/app/pages-common/money-categories/money-categories.component';
import { MoneyManagerComponent } from 'src/app/pages-common/money-manager/money-manager.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxColorsModule } from 'ngx-colors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TestComponent } from 'src/app/pages-common/test/test.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastModule } from 'primeng/toast';
import { PostFormComponent } from 'src/app/pages-admin/post-form/post-form.component';
import { PostComponent } from 'src/app/pages-admin/post/post.component';
import { PostCreateComponent } from 'src/app/pages-admin/post-create/post-create.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    DashboardComponent,
    ItemComponent,
    InventoryComponent,
    InventoryDetailComponent,
    MoneyManagerComponent,
    MoneyAccountsComponent,
    MoneyCategoriesComponent,
    TestComponent,
    PostComponent,
    PostCreateComponent,
    PostFormComponent
  ],
  imports: [
    // BrowserAnimationsModule,
    CommonModule,
    ComponentsCommonModule,
    ComponentsAdminModule,
    // ColorPickerModule,
    NgxColorsModule,
    ReactiveFormsModule,
    RouterModule.forChild(AdminLayoutRoutes),
    AdminLayoutRoutingModule,
    NgbToastModule,
    ToastModule,
    EditorModule
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class AdminLayoutModule { }
