
export class SlugUtils {
  
    static convertToSlug(Text?: string) {
        if( Text ){
          return Text.toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '');
        }
        return '';
    }
}