import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { Observable, interval, take, firstValueFrom, lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService extends BaseService{

  path: string = `${environment.apiBasePath}`;
  taxonomyRoute!: string;
  headers!: HttpHeaders;


  constructor(
    injector: Injector,
    protected http: HttpClient
  ) {
    super(injector);
    this.buildHeaders();
  }

  createTaxonomyItem(taxonomyTipe: string, body: any): Observable<any> {
    this.taxonomyRoute = `${this.path}/${taxonomyTipe}`;
    // console.log(this.taxonomyRoute);
    return this.http.post<any>( `${this.taxonomyRoute}`, body, { headers: this.headers});
  }

  updateOne(taxonomyTipe: string, body: any, id: string): Observable<any> {
    this.taxonomyRoute = `${this.path}/${taxonomyTipe}/${id}`;
    // console.log(this.taxonomyRoute);
    return this.http.put<any>( `${this.taxonomyRoute}`, body, { headers: this.headers});
  }

  findAll(taxonomyTipe: string): Observable<any> {
    this.taxonomyRoute = `${this.path}/${taxonomyTipe}`;
    return this.http.get<any>( `${this.taxonomyRoute}`, { headers: this.headers});
  }

  findOne(taxonomyTipe: string, id: string): Observable<any> {
    this.taxonomyRoute = `${this.path}/${taxonomyTipe}/${id}`;
    return this.http.get<any>( `${this.taxonomyRoute}`, { headers: this.headers});
  }

  // async getProducts(): Promise<any> {    
  //   const products = await firstValueFrom(
  //     // this.http.get<WCProduct>(`${this.path}${this.products}?slug=${postSlug}`, {headers: this.headers}
  //     this.http.get<any>( `${this.path}${this.products}`, { headers: this.headers})
  //       .pipe(
  //         map(products => {
  //           // return new WCProduct(products[0]);
  //           return products;
  //         })
  //   ));
  //   return products;
  // }


  buildHeaders(): void {
    this.headers = new HttpHeaders();
    // this.headers.append( 'Authorization', 'Basic Y2tfZDk5YmRkZWM1ZTc3MzUxYjI5ODRjODAwYzBlNWM1YTU5MTZhYmQyYzpjc19jYjIyMzMwOTFjYjgzNWYyNjkyYzQ5ZDY2NTViNDgzNmVkOTNjZjJh' );
    this.headers = this.headers.append( 
      'Authorization', 
      'Basic Y2tfMjRjYjI2OGQxNzE2NGIzMWVlNzU2N2U3Yjk3Njc1MWYyMDE0NTY5Mzpjc19hOTU3ODk3ZDUxYzE2MDNkNDM4NmQzNzQ2ZGEyYjI4MTQ3ZjE2ZDEy' 
    );
    // console.log(this.headers);
  }
}
