import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/services-common/common.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],
  // providers: [MessageService]
})
export class AdminLayoutComponent implements OnInit {


  constructor(
    private commonService: CommonService
  ) {

  }

  ngOnInit(): void {
    this.commonService.addToastMessageEvent
      .subscribe(() => {});
      // .subscribe( (data: any) => {
      // });

  }
}
