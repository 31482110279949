import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services-common/common.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy{

  @ViewChild('myDrop') myDrop: any;

  public cart: any[] = [];


  constructor(
    private commonService: CommonService,
  ){}


  ngOnInit(): void {
    this.commonService
    .addToCartEvent
    .subscribe((data:any) => {
      // console.warn(data);
      this.cart.push(data);
      console.log('mehhhhhhhh!!' + this.cart);

      // setTimeout( () => {
      //   this.myDrop.open();
      // });

      // setTimeout( () => {
      //   this.myDrop.close();
      // }, 1500);
    });
  }

  ngAfterViewInit(): void {
  }

  onToggle() {
    // console.warn(this.state);
    this.commonService.sidebarOpened = !this.commonService.sidebarOpened;
    this.commonService.sidebarToggle(this.commonService.sidebarOpened);
    // console.warn(this.state);
  }

  ngOnDestroy(): void {
    
  }

}
