import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FrontLayoutRoutes, FrontLayoutRoutingModule } from './front-layout-routing.module';
import { FrontLayoutComponent } from './front-layout.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsFrontModule } from 'src/app/components-front/components-front.module';
import { ComponentsCommonModule } from 'src/app/components-common/components-common.module';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { StoriaComponent } from 'src/app/pages/storia/storia.component';
import { ContattiComponent } from 'src/app/pages/contatti/contatti.component';
import { CategoriaProdottoComponent } from 'src/app/pages/categoria.prodotto/categoria.prodotto.component';
import { ProductDetailComponent } from 'src/app/pages/product-detail/product-detail.component';
import { EventiPrincipaliComponent } from 'src/app/components-front/eventi-principali/eventi-principali.component';



@NgModule({
  declarations: [
    FrontLayoutComponent,
    HomeComponent,
    StoriaComponent,
    ContattiComponent,
    CategoriaProdottoComponent,
    ProductDetailComponent,
    EventiPrincipaliComponent
  ],
  imports: [
    RouterModule.forChild(FrontLayoutRoutes),
    CommonModule,
    FrontLayoutRoutingModule,
    ComponentsFrontModule,
    ComponentsCommonModule,
    NgbCollapseModule
  ]
})
export class FrontLayoutModule { }
