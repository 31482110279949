import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-money-info-card',
  templateUrl: './money-info-card.component.html',
  styleUrls: ['./money-info-card.component.scss']
})
export class MoneyInfoCardComponent implements OnInit, OnChanges, OnDestroy {

  @Input() item!: any;
  @Input() baseRoute!: any;
  @Output() modalUpdateOpener = new EventEmitter<any>();


  constructor() {
  }

  ngOnInit() {
    // console.warn(this.item);
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  public goToDetail(slug: string): void{
    alert(slug);
  }

  showDetail(item: any) {
    console.warn(item);
    this.modalUpdateOpener.emit(item);
  }

  ngOnDestroy(): void {
    
  }

}
