import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services-common/auth.service';
import { CommonService } from 'src/app/services-common/common.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  public form = new FormGroup ({
    username  : new FormControl(''),
    password  : new FormControl(''),
    rememberMe: new FormControl(false),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private authService: AuthService,
  ) {}


  async login(): Promise<void> {
    this.commonService.AClicked(true);
    console.warn(this.form.value);
    this.authService
      .login(this.form.value)
      .subscribe((data: any) => {
        console.warn(data);
        localStorage.setItem( `${environment.prefix}_token`, data.access_token );
        this.commonService.AClicked(false);
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Save success' });
        this.commonService.sendMessage({ severity: 'success', summary: 'Success', detail: 'Logged successfully' });

        setTimeout( () => {
          this.router.navigate(['/', 'admin', 'dashboard']);
        }, 1000);
      });
  }

}
