import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from '@angular/router';
import { environment } from 'src/environments/environment';
import { RouteInformation } from 'src/app/interfaces-common/RouteInformation';
import { CommonService } from 'src/app/services-common/common.service';
import { ProductAndServicesService } from 'src/app/services-common/product-and-services.service';


@Component({
  selector: 'app-categoria-prodotto',
  templateUrl: './categoria.prodotto.component.html',
  styleUrls: ['./categoria.prodotto.component.scss']
})
export class CategoriaProdottoComponent implements OnInit, OnDestroy {

  public productCategory!: string;
  public routeInformation: RouteInformation = {};
  public productsCategory!: any;
  public products!: any[];
  public googleMapsAddressLink!: string;
  public environment = environment;

  constructor(
    private route: ActivatedRoute,
    private titleService:Title,
    private productsAndServices: ProductAndServicesService,
    private commonService: CommonService
  ){ }

  
  
  async ngOnInit(): Promise<void> {
    this.googleMapsAddressLink = `${environment.googleMapsAddressLink}`;
    this.route.paramMap.subscribe( async paramMap => {
      this.routeInformation.slug = paramMap.get('slug');
      this.commonService.AClicked(true);
      
      if (this.routeInformation.slug) {
        await this.initComponent(this.routeInformation.slug);
      }
      this.commonService.AClicked(false);
      this.titleService.setTitle(`${environment.appUrl} - ${this.routeInformation.slug}`);
    });
  }


  async initComponent( categorySlug: string ): Promise<void> {
    if (this.routeInformation.slug) {
      this.productsCategory = (await this.productsAndServices.getProductCategoryBySlug(this.routeInformation.slug))[0];
    }
    const productsByCategoryId = await this.productsAndServices.getProductsByCategoryId(this.productsCategory._id);

    this.products = productsByCategoryId;
    console.warn({
      routeInformationSlug: this.routeInformation.slug,
      id: productsByCategoryId,
      productsCategory: this.productsCategory
    });
  }

  ngOnDestroy(): void {
    
  }

}
