<div class="container-fluid py-4">

    <div class="card shadow-lg mx-4 card-profile-bottom-medium">
      <div class="card-body p-3">

        <div class="row gx-4">
            <div class="col-md-12 justify-content-end">
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button class="btn btn-outline-primary mb-2 me-2" (click)="openXl(content)">
                        Add Account
                    </button>
                </div>
            </div>
        </div>

        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img src="../assets/img/team-1.jpg" alt="profile_image" class="w-100 border-radius-lg shadow-sm">
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">
                Ricerca:
              </h5>
              <p class="mb-0 font-weight-bold text-sm">
                Public Relations
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <div class="nav-wrapper position-relative end-0">
              <ul class="nav nav-pills nav-fill p-1" role="tablist">
                <!-- <li class="nav-item" role="presentation">
                  <a class="nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
                    <i class="ni ni-app"></i>
                    <span class="ms-2">App</span>
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false" tabindex="-1">
                    <i class="ni ni-email-83"></i>
                    <span class="ms-2">Messages</span>
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false" tabindex="-1">
                    <i class="ni ni-settings-gear-65"></i>
                    <span class="ms-2">Settings</span>
                  </a>
                </li>
                <div class="moving-tab position-absolute nav-link" 
                  style="padding: 0px; transition: all 0.5s ease 0s; transform: translate3d(0px, 0px, 0px); width: 82px;">
                  <a class="nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center " data-bs-toggle="tab" 
                    href="javascript:;" role="tab" aria-selected="true">-</a>
                </div> -->
              </ul>
            </div>
          </div>
        </div>
        <div class="row gx-4">
          <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center" tabindex="-1"
              (click)="search()"
            >
              <!-- <i class="ni ni-settings-gear-65"></i> -->
              <i class="fa fa-search" aria-hidden="true"></i>
              <span class="ms-2">Cerca</span>
            </a>
          </div>
        </div>
      </div>
    </div>


    <div class="row mt-6">
      <!-- <div class="" [ngClass]="isSideInformationVisible ? 'col-md-8' : 'col-md-12' "> -->
        <!-- <div class="" [ngClass]="'col-md-8'">
            <app-form-card></app-form-card>
      </div> -->
      <div class="col-md-4 mb-5" *ngFor="let account of accounts">
        <app-money-info-card  
            [item]="account"
            [baseRoute]="'/admin/money-account/'"
            (modalUpdateOpener)="open(content, $event)"
        ></app-money-info-card >
      </div>
    </div>
  </div>


  <ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title">New Money Account</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">

        <form [formGroup]="accountForm">
            <div class="row">
                <div class="col-12 col-md-6 col-xl-6">
                    <label for="date">Nome</label>
                    <input class="form-control datepicker" placeholder="Please select date" type="text" 
                        id="name" name="name" (change)="buildPermalink()" formControlName="name"
                    />
                    <span class="slug">Slug:</span>
                    <span id="slug-span" class="input-read-only">
                        {{accountForm.controls['slug'].value}}
                    </span>
                    <!-- onfocus="focused(this)" onfocusout="defocused(this)" -->
                </div>
                <div class="col-12 col-md-2 col-xl-2">
                    <label for="color">Colore</label>
                    <!-- <input [(colorPicker)]a="color" [style.background]="color"/> -->
                    <div class="form-control form-control-for-form">
                        <ngx-colors ngx-colors-trigger class="ngx-color-form" id="color" name="color"
                            formControlName="color"
                        >
                        </ngx-colors>
                        <span>{{accountForm.controls['color'].value }}</span>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-xl-4">
                    <label for="amount">Ammontare</label>
                    <input class="form-control" placeholder="Please select amount" type="number" 
                        id="fund" name="fund" formControlName="fund"
                    />
                </div>
            </div>
    
            <hr class="horizontal dark">
            <p class="text-uppercase text-sm">Content</p>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <textarea type="text" name="content" id="content"
                                  class="form-control form-control-alternative"
                                  placeholder="Content" required formControlName="content"
                        ></textarea>
                        <!-- @if ($errors->has('content'))
                            <span class="invalid-feedback" role="alert">
                                    <strong>{{ $errors->first('content') }}</strong>
                                </span>
                        @endif -->
                    </div>
                </div>
            </div>
        </form>

	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="saveTransaction()">Save</button>
	</div>
</ng-template>