<div class="container-fluid py-4">
    <form name="post" autocomplete="off" enctype="multipart/form-data" novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">{{pageInformation.title}}</p>
                            <!-- <button class="btn btn-primary btn-sm ms-auto">Settings</button> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <p class="text-uppercase text-sm">Base Info</p>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="title">{{pageInformation.title}}</label>
                                    <input type="text" name="title" id="title"
                                        class="form-control form-control-alternative mb-2" (change)="buildPermalink(form, 'title')"
                                        placeholder="Title" value="" required autofocus formControlName="title"
                                    />
                                    <!-- @if ($errors->has('title'))
                                        <span class="invalid-feedback" role="alert">
                                            <strong>{{ $errors->first('title') }}</strong>
                                        </span>
                                    @endif -->
                                    <span class="slug">Permalink:</span>
                                    <span id="slug-span" class="input-read-only">
                                        {{form.controls['slug'].value}}
                                    </span>

                                </div>
                            </div>
                            <div class="col-6">
                                <label class="form-control-label" for="featured_image_obj">Featured Image</label>
                                <div class="form-group">
                                    <input #fileUpload type="file" name="featured_image_obj" id="featured_image_obj" 
                                        class="form-control" (change)="uploadFile($event)"
                                    />
                                </div>
                            </div>
                        </div>    
                        <hr class="horizontal dark">
                        <p class="text-uppercase text-sm">Content</p>
                        <div class="row">
                            <div class="col-md-12 mimmo">
                                <div class="form-group">
                                    <!-- class="form-control form-control-alternative" -->
                                    <editor
                                        name="content" id="content" placeholder="Content"
                                        formControlName="content"
                                        [init]="{ plugins: 'lists link image table code help wordcount' }"
                                    ></editor>

                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6"></div>
                            <div class="col-6">
                                <div class="text-center">
                                    <button type="submit" class="btn btn-success mt-3 float-end" 
                                        (click)=" (productId) ? update() : save()"
                                    >
                                        <span *ngIf="productId; else saveText">
                                            Update
                                        </span>
                                        <ng-template #saveText>
                                            <span> Save </span>
                                        </ng-template>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr class="my-4" />
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-profile">
                    <img class="card-img-top" alt="{{form.controls['slug'].value }}" [src]="
                        (form.controls['featured_image'].value || form.controls['featured_image'].value != '' ) 
                        ? environment.basePath + '/img/' + this.taxonomy + '/' + form.controls['slug'].value + '/' + form.controls['featured_image'].value 
                    : environment.defaultNoImage"
                    />
    
                        <hr class="my-3" />
                        <app-timeline-one
                        [steps]="[
                            {name: 'sadsada', description: 'sddads'},
                            {name: 'oooo', description: 'meh'},
                        ]"
                        ></app-timeline-one>
                        <!-- <div class="form-group">
                            <label class="form-control-label" for="brand_id">{{ __('Brand') }}</label>
                            <select class="form-control" name="brand_id" id="brand_id">
                                @foreach ($brands as $brand)
                                    <option value="{{ $brand->id }}">{{ $brand->title }}</option>
                                @endforeach
                            </select>
                        </div>
                        <hr class="horizontal dark my-4" />
                        <div class="form-group">
                            <label class="form-control-label" for="category_id">{{ __('Category') }}</label>
                            <select class="form-control" name="category_id" id="category_id">
                                @foreach ($categories as $category)
                                    <option value="{{ $category->id }}">{{ $category->title }}</option>
                                @endforeach
                            </select>
                        </div>
                        <hr class="horizontal dark my-4" />
                        <div class="form-group">
                            <label class="form-control-label" for="location_id">{{ __('Locations') }}</label>
                            <select class="form-control" name="location_id" id="location_id">
                                @foreach ($locations as $location)
                                    <option value="{{ $location->id }}">{{ $location->title }}</option>
                                @endforeach
                            </select>
                        </div>
                        <hr class="horizontal dark my-4" />
                        <div class="form-group">
                            <label class="form-control-label" for="rack_id">{{ __('Rack') }}</label>
                            <select class="form-control" name="rack_id" id="rack_id">
                                @foreach ($racks as $rack)
                                    <option value="{{ $rack->id }}">{{ $rack->title }}</option>
                                @endforeach
                            </select>
                        </div>
                        <hr class="horizontal dark my-4" />
                        <div class="form-group">
                            <label class="form-control-label" for="zone_id">{{ __('Zone') }}</label>
                            <select class="form-control" name="zone_id" id="zone_id">
                                @foreach ($zones as $zone)
                                    <option value="{{ $zone->id }}">{{ $zone->title }}</option>
                                @endforeach
                            </select>
                        </div>
                        <hr class="horizontal dark my-4" />
                        <div class="form-group">
                            <label class="form-control-label" for="level_id">{{ __('Level') }}</label>
                            <select class="form-control" name="level_id" id="level_id">
                                @foreach ($levels as $level)
                                    <option value="{{ $level->id }}">{{ $level->title }}</option>
                                @endforeach
                            </select>
                        </div>
                        <hr class="horizontal dark my-4" />
                        <label class="form-control-label" for="featured_image_obj">{{ __('Featured Image') }}</label>
                        <div class="form-group">
                            <input type="file" name="featured_image_obj" id="featured_image_obj" class="form-control">
                        </div> -->
                </div>
            </div>
        </div>
    </form>
</div>
