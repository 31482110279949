<app-hero 
  url="https://visitareprezza.it/engine/wp-content/uploads/2021/03/banner-img.jpg"
  title="Storia" subTitle="Prezza e la sua storia"
></app-hero>

<div class="container mt--8 pb-5 col-12 mt-n5">
    <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12">
          <div class="card bg-secondary shadow border-0">
            <div class="card-header pb-5">
            <!-- <div class="card-header bg-transparent pb-5"> -->
              <!-- <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div> -->
    
              <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                  <hr class="mt-5">
                  <img src="https://visitareprezza.it/engine/wp-content/uploads/2021/11/belvedere_prezza.jpg" 
                    class="img-wrap-story"
                  />
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="btn-wrapper text-center">              </div>
                    <p>Prezza è un piccolo comune nella provincia dell’Aquila. 
                      Uno splendido borgo medievale che spunta sulla sommità di una collina.</p>
                    <p>Un piccolo gioiello incastonato tra le montagne che appartiene alla Comunità montana Peligna, 
                      noto anche come “il terrazzo della Valle Peligna”. </p>
                    <p> Il luogo era già abitato durante il periodo dei romani, noto col nome di <strong>Pagus Laverna</strong>.</p>
                    <p>Nel Medioevo, prima del 1000, il feudo era soggetto al potete dell’abbazia di San Clemente a Casauria, 
                      e aveva il nome di Villa Carrene. 
                      Numerosi reperti archeologici sono stati trovati nelle zone di Colle e contrada San Giovanni.</p>
                    <p>Il Castello medievale era una delle principali fortezze della Valle Peligna, che presidiavano l’accesso 
                      dal valico di Cocullo.</p>
                </div>
              </div>
              <br><br><br>
              <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                  <p> Il feudo cambiò il nome nel XV secolo in Rocca di Sale, per via delle decime che si pagavano per il possesso, 
                    e solo due secoli più tardi prese il nome attuale. Nel 1706 il castello fu distrutto da un forte terremoto, 
                    e rimase solo la torre campanaria della chiesa madre di Santa Lucia.</p>
                  <p>Il centro storico di Prezza sorge in una posizione privilegiata, molto panoramica e suggestiva. 
                    Infatti, da quello sperone di montagna è possibile godere una delle vedute più belle della Valle Peligna. 
                    La visita del paese non può che iniziare dalla chiesa di Santa Lucia (XV secolo) protettrice del paese, 
                    per proseguire poi verso la chiesa di San Giuseppe (1300-1400), il Palazzo baronale per poi concludersi con i 
                    sottopassaggi e gli archi disseminati all’interno della cittadina.</p>
                </div>
                <div class="col-lg-6 col-md-6">
                  <hr class="mt-5">
                  <img src="https://visitareprezza.it/engine/wp-content/uploads/2021/11/img-1-scaled.jpg" 
                    class="img-wrap-story"
                  />
                </div>
              </div>
              <br><br><br>
              <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                  <hr class="mt-5">
                  <img src="https://visitareprezza.it/engine/wp-content/uploads/2021/09/centro_storico_museo_prezza-600x450.jpg" 
                    class="img-wrap-story"
                  />
                </div>
                <div class="col-lg-6 col-md-6">
                  <p>Le origini pastorali e contadine caratterizzano la cucina tradizionale del posto.</p> 
                  <p> All’interno del borgo è possibile trovare delle piccole botteghe enogastronomiche legate ai prodotti tipici.</p> 
                  <p> Nel centro sono presenti due ristoranti che propongono dell‘ottima cucina locale di alta qualità e genuina. </p>
                  <p> Meta ideale per gli appassionati della natura, del relax ma anche per chi ha un interesse di tipo storico e 
                    culturale.</p>
                </div>
              </div>

              <br><br><br>
            </div>
          </div>
        </div>
    </div>
</div>