<div class="card card-profile">
    <!-- (click)="goToDetail(item.slug)" -->
    <!-- *ngIf="item.featured_image" -->
    <img class="card-img-top" alt="{{item.slug}}" [src]="
        (item.featured_image || item.featured_image != '' ) 
        ? environment.basePath + '/img/' + type + '/' + item.slug + '/' + item.featured_image 
      : '/assets/img/no_image_product.jpg'"
    >
    
    <div class="row justify-content-center">
      <div class="col-4 col-lg-4 order-lg-2">
        <div class="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
          <a href="j">
            <img src="../assets/img/team-2.jpg" class="rounded-circle img-fluid border border-2 border-white">
          </a>
        </div>
      </div>
    </div>
    <div class="card-header text-center border-0 pt-0 pt-lg-2 pb-4 pb-lg-3">
      <div class="d-flex justify-content-between">
        <a class="btn btn-sm btn-info mb-0 d-none d-lg-block" (click)="addToCart(item)" >Add to Cart</a>
        <a href="" class="btn btn-sm btn-info mb-0 d-block d-lg-none"><i class="ni ni-collection"></i></a>
        <a class="btn btn-sm btn-dark float-right mb-0 d-none d-lg-block" 
          [routerLink]="[baseRoute, item._id]"
        >
          Detail
        </a>
        <a href="" class="btn btn-sm btn-dark float-right mb-0 d-block d-lg-none"
          [routerLink]="[baseRoute, item._id]"
        >
          <i class="ni ni-email-83"></i>
        </a>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <div class="d-grid text-center">
              <span class="text-lg font-weight-bolder">
                {{item.stock_quantity === null ? 'N.D.' : item.stock_quantity}}
              </span>
              <span class="text-sm opacity-8">Quantity</span>
            </div>
            <div class="d-grid text-center mx-4">
              <span class="text-lg font-weight-bolder">10</span>
              <span class="text-sm opacity-8">Photos</span>
            </div>
            <div class="d-grid text-center">
              <span class="text-lg font-weight-bolder">89</span>
              <span class="text-sm opacity-8">Comments</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4">
        <h5 style="text-transform: capitalize;">
          {{item.name}} <span class="font-weight-light">, {{item.id}}</span>
        </h5>
        <div class="h6 font-weight-300 text-capitalize">
          <i class="ni location_pin mr-2"></i>{{item.title}}
        </div>
        <div class="h6 mt-4">
          <i class="ni business_briefcase-24 mr-2"></i>----------</div>
        <div>
          <i class="ni education_hat mr-2"></i>Stock Status: 
            <span style="text-transform: capitalize;">{{item.stock_status}}</span>
        </div>
      </div>
    </div>
  </div>
