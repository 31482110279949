<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">{{timelineTitle}}</h6>
                    <div id="content">
                        <ul class="timeline">
                            <li class="event" data-date="12:30 - 1:00pm" *ngFor="let step of steps">
                                <h5>{{step.name}}</h5>
                                <p>{{step.description}}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>