import {Component, OnInit, OnDestroy, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductAndServicesService } from 'src/app/services-common/product-and-services.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  public isCollapsed = true;
  public productCategories!: any[];
  public showSpinner!: boolean;
  public appName!: string;

  @ViewChild('navigation') navigation!: ElementRef;

  constructor(
    private router: Router, 
    private productAndServicesService: ProductAndServicesService
  ) { }


  async ngOnInit(): Promise<void>{
    this.appName = `${environment.appName}`;
    var html = document.getElementsByTagName("html")[0];
    html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    // body.classList.add("bg-default");
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });

    this.showSpinner = true;
    await this.initProductCategories();
    this.showSpinner = false;
  }

  async initProductCategories(): Promise<void> {
    this.productCategories = await this.productAndServicesService.getProductsCategories();
    // // this.productCategories.map(data => {
    // //   data.name = data.name.replace(/&amp;/g, "&");
    // //   data.description = data.description.replace(/&amp;/g, "&");
    // // });

    // // let productCategoriesEnabled: string[] = [];
    // // this.productCategories.forEach((element, index)=>{
    // //   if(element.slug !== "senza-categoria"){
    // //     productCategoriesEnabled.push(element);
    // //   }
    // // });
    // // this.productCategories = productCategoriesEnabled;
  }

  toggle() {
    this.navigation.nativeElement.classList.add('collapse', 'show');
    console.warn(this.navigation);
  }

}
