import {NgModule} from '@angular/core';
import {NgbModule, NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule, NgFor, NgIf, NgTemplateOutlet} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NavbarComponent} from './navbar/navbar.component';
import {FooterComponent} from './footer/footer.component';
import {HeroComponent} from './hero/hero.component';
import { MoneyInfoCardComponent } from './money-info-card/money-info-card.component';
// import { MenuComponent } from './menu/menu.component';
// import { RisultatiComponent } from './risultati/risultati.component';
// import { RicercaComponent } from './ricerca/ricerca.component';
import { CardGroupComponent } from './card-group/card-group.component';
import { CardOverlayedComponent } from './card-overlayed/card-overlayed.component';
import { CardStaticComponent } from './card-static/card-static.component';
import { InventoryFormComponent } from './inventory-form/inventory-form.component';
import { ComponentsCommonModule } from "../components-common/components-common.module";
import { ReactiveFormsModule } from '@angular/forms';
import { InventoryInfoCardComponent } from './inventory-info-card/inventory-info-card.component';
import { ToastModule } from 'primeng/toast';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TaxonomyInfoCardComponent } from './taxonomy-info-card/taxonomy-info-card.component';

@NgModule({
    declarations: [
        NavbarComponent,
        SidebarComponent,
        MoneyInfoCardComponent,
        CardGroupComponent,
        CardOverlayedComponent,
        CardStaticComponent,
        FooterComponent,
        InventoryFormComponent,
        InventoryInfoCardComponent,
        TaxonomyInfoCardComponent
        // HeroComponent,
        // EventiPrincipaliComponent,
        // MenuComponent,
        // RisultatiComponent,
        // RicercaComponent
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        MoneyInfoCardComponent,
        CardGroupComponent,
        CardOverlayedComponent,
        CardStaticComponent,
        InventoryFormComponent,
        InventoryInfoCardComponent,
        TaxonomyInfoCardComponent
        // HeroComponent,
        // EventiPrincipaliComponent,
        // MenuComponent,
        // RicercaComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        ReactiveFormsModule,
        ComponentsCommonModule,
        ToastModule,
        EditorModule
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class ComponentsAdminModule {}
