<div class="container-fluid py-4">
    <div class="row mt-6">
      <app-post-form
        [pageInformation]="pageInformation"
        [productId]="productId"
        [product]="product"
        [categories]="categories"
      >
      </app-post-form>
    </div>
  </div>
