import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-storia',
  templateUrl: './storia.component.html',
  styleUrls: ['./storia.component.scss']
})
export class StoriaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
