<div class="container-fluid py-4">

    <div class="card shadow-lg mx-4 card-profile-bottom-medium">
      <div class="card-body p-3">
        <form name="post-new" autocomplete="off" enctype="multipart/form-data" novalidate [formGroup]="form">
          <div class="row gx-4">
              <div class="col-auto">
                <div class="avatar avatar-xl position-relative">
                  <img src="../assets/img/team-1.jpg" alt="profile_image" class="w-100 border-radius-lg shadow-sm">
                </div>
              </div>
              <div class="col-auto my-auto">
                <div class="h-100">
                  <h5 class="mb-1">
                    Ricerca:
                  </h5>
                  <p class="mb-0 font-weight-bold text-sm">
                    Public Relations
                  </p>
                </div>
              </div>
              <!-- <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                <div class="nav-wrapper position-relative end-0">
                  <ul class="nav nav-pills nav-fill p-1" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
                        <i class="ni ni-app"></i>
                        <span class="ms-2">App</span>
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false" tabindex="-1">
                        <i class="ni ni-email-83"></i>
                        <span class="ms-2">Messages</span>
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false" tabindex="-1">
                        <i class="ni ni-settings-gear-65"></i>
                        <span class="ms-2">Settings</span>
                      </a>
                    </li>
                    <div class="moving-tab position-absolute nav-link" 
                      style="padding: 0px; transition: all 0.5s ease 0s; transform: translate3d(0px, 0px, 0px); width: 82px;">
                      <a class="nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center " data-bs-toggle="tab" 
                        href="javascript:;" role="tab" aria-selected="true">-</a>
                    </div>
                  </ul>
                </div>
              </div> -->
              <div class="col-md-2">
                <div class="form-group">
                    <label for="type" class="form-control-label">Type</label>
                    <select class="form-control" name="type" id="type" formControlName="type">
                        <option value="">Select one</option>
                        <option value="{{productType}}" *ngFor="let productType of productTypes">
                          {{productType}}
                        </option>
                    </select>
                </div>
              </div>
              <div class="col-md-2">
                  <div class="form-group">
                      <label for="status" class="form-control-label">Status</label>
                      <select class="form-control" name="status" id="status" formControlName="status">
                          <option value="">Select one</option>
                          <option value="{{productStatus}}" *ngFor="let productStatus of productStatuses">
                            {{productStatus}}
                          </option>
                      </select>
                  </div>
              </div>
              <div class="col-md-2 justify-content-end">
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                      <!-- <button class="btn btn-outline-primary mb-2 me-2" (click)="openXl(content)">
                        Add Transaction
                      </button> -->
                      <button class="btn btn-outline-primary mb-2 me-2" [routerLink]="'/admin/inventory/create'">
                        Add Product
                      </button>
                  </div>
              </div>
          </div>

          <div class="row gx-4">
            <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
              <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center" tabindex="-1"
                (click)="search()"
              >
                <!-- <i class="ni ni-settings-gear-65"></i> -->
                <i class="fa fa-search" aria-hidden="true"></i>
                <span class="ms-2">Cerca</span>
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>


    <div class="row mt-6">
      <!-- <div class="" [ngClass]="isSideInformationVisible ? 'col-md-8' : 'col-md-12' "> -->
        <!-- <div class="" [ngClass]="'col-md-8'">
            <app-form-card></app-form-card>
      </div> -->
      <div class="col-md-4 mb-5" *ngFor="let product of products">
        <app-inventory-info-card 
            [item]="product"
            [type]="'product'"
            [baseRoute]="'/admin/inventory/'"
            
        ></app-inventory-info-card>
        <!-- (modalUpdateOpener)="open(content, $event)" -->
      </div>
    </div>
</div>
