<!-- <div class="header bg-gradient-danger py-7 py-lg-8"> -->
  <!-- style="background-image: url('https://visitareprezza.it/engine/wp-content/uploads/2021/11/beer-irish-pub-drink.jpg');" -->
  <div class="header py-7 py-lg-8 demo-wrap bg-hero" *ngIf="productsCategory" 
    style="--radius: url({{environment.basePath}}/img/product-categories/{{productsCategory.slug}}/{{productsCategory.featured_image}});"
  >
    <div class="container demo-content">
      <div class="header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12">
            <h1 class="text-white" style="text-transform: capitalize;">{{routeInformation.slug}}</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div> -->
</div>
  <!-- Page content -->
<div class="container mt--8 pb-5 mt-6">

    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-xs-12 padd-bott card-hover" *ngFor ="let product of products;">
          <div class="card shadow border-0">
            <img class="card-img-top normalize-img-size" 
                src="{{environment.basePath}}/img/{{product.type | lowercase}}/{{product.slug}}/{{product.featured_image}}" 
                alt="{{product.slug}}"
            >
            <div class="card-body">
              <!-- <div class="row justify-content-center"> -->
                <a href="" class="card-title h5 d-block text-darker" 
                  [routerLink]="['/categorie/', routeInformation.slug, product.slug]"
                >
                  {{product.title}}
                </a>
                <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">
                  {{product.subtitle}}
                </span>
                <div class="row">
                <!-- <div class="col-lg-6 col-md-6 col-xs-6" *ngFor ="let options of item.attributes;">
                    <h5 class="card-title">{{options.options}}</h5>
                </div> -->
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 text-center" *ngIf="product.phone">
                  <h5 class="card-title">
                    <a href="tel:{{product.phone}}">
                      <!-- <i class="fa fa-2x fa-phone"></i> -->
                      <i class="fas fa-2x fa-phone"></i>
                    </a>
                  </h5>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 text-center" *ngIf="product.email">
                  <h5 class="card-title">
                    <a href="mailto:{{product.email}}">
                      <!-- <i class="ni fa-2x ni-email-83" style="font: normal normal normal 2rem / 1 NucleoIcons;"></i>-->
                      <i class="fas fa-2x fa-envelope"></i>
                    </a>
                  </h5>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 text-center" *ngIf="product.address">
                  <h5 class="card-title">
                    <a href="{{googleMapsAddressLink}}{{product.address}}" target="_blank">
                      <!-- <i class="ni fa-2x ni-pin-3" style="font: normal normal normal 2rem / 1 NucleoIcons;"></i> -->
                      <i class="fas fa-2x fa-map-marker-alt"></i>
                    </a>
                  </h5>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 text-center" *ngIf="product.website">
                  <h5 class="card-title">
                    <a href="{{product.website}}" target="_blank">
                      <i class="fa fa-2x fa-globe"></i>
                      <!-- <i class="fas fa-globe"></i> -->
                    </a>
                  </h5>
                </div>
              </div>
              <h3 class="card-title">{{product.name}}</h3>
              <p class="card-text" [innerHTML]="product.short_description"></p>
              <a href="" class="btn btn-primary" [routerLink]="['/categorie/', routeInformation.slug, product.slug]">
                Vai alla descrizione
              </a>
            </div>
          </div>
        </div>
    </div>
</div>
