import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { FormGroup, FormControl } from '@angular/forms';
import { WCProduct } from 'src/app/models/wc-product';
import { CommonService } from 'src/app/services-common/common.service';
import { FileService } from 'src/app/services-common/file.service';
import { InventoryService } from 'src/app/services-common/inventory.service';
import { TaxonomyService } from 'src/app/services-common/taxonomy.service';
import { environment } from 'src/environments/environment';
import { ProductTypeEnum } from 'src/app/Enums/ProductTypeEnum';
import { ProductStatusEnum } from 'src/app/Enums/ProductStatusEnum';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  providers: [MessageService]
})
export class InventoryComponent implements OnInit, OnDestroy {

  // public product?: WCProduct;
  // public products?: WCProduct[];
  public product!: any;
  public products!: any[];
  public terms?: any[];

  public brands!: any[];
  public zones!: any[];
  public racks!: any[];
  public locations!: any[];
  public levels!: any[];
  public categories!: any[];
  // public showSpinner!: boolean;
  // public googleMapsAddressLink!: string;
  public productTypes!: string[];
  public productStatuses!: string[];
  
  form = new FormGroup({
    type: new FormControl(''),
    status: new FormControl(''),
  });

  constructor(
    private titleService:Title,
    private commonService: CommonService,
    private inventoryService: InventoryService,
    private messageService: MessageService,
    private taxonomyService: TaxonomyService,
    private fileService: FileService
  ) { }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(`${environment.appName}`);
    await this.initTerms();
    await this.initProducts();
    this.buildTaxonomies();

    this.productTypes = this.getProductTypes();
    this.productStatuses = this.getProductStatuses();
  }

  async initProducts(): Promise<void> {
    this.commonService.AClicked(true);
      this.inventoryService
        .findAll()
        .subscribe((data: any) => {
          // console.warn(data);
          this.products = data;
          // console.log(this.products);
          this.commonService.AClicked(false);
        });
  }

  async initTerms(): Promise<void> {
    // this.terms = await this.wpTermsService.getTerms();
    // this.activityName = this.product.name;
    console.log(this.terms);
  }

  search(){
    alert('mimmo');
  }

  buildTaxonomies() {
    this.taxonomyService.findAll('categories')
    .subscribe((data: any) => {
        // console.warn(data);
        this.categories = data;
    });

    this.taxonomyService.findAll('brands')
    .subscribe((data: any) => {
        // console.warn(data);
        this.brands = data;
    });

    this.taxonomyService.findAll('zones')
    .subscribe((data: any) => {
        // console.warn(data);
        this.zones = data;
    });

    this.taxonomyService.findAll('racks')
    .subscribe((data: any) => {
        // console.warn(data);
        this.racks = data;
    });
    this.taxonomyService.findAll('locations')
    .subscribe((data: any) => {
        // console.warn(data);
        this.locations = data;
    });

    this.taxonomyService.findAll('levels')
    .subscribe((data: any) => {
        // console.warn(data);
        this.levels = data;
    });
  }

  getProductTypes() {
    const productTypes = Object.keys(ProductTypeEnum).filter((item) => {
      return isNaN(Number(item));
    });
    // console.log(productTypes.join("\n"));
    
    return productTypes;
  }

  getProductStatuses() {
    const productStatuses = Object.keys(ProductStatusEnum).filter((item) => {
      return isNaN(Number(item));
    });
    // console.log(productStatuses.join("\n"));
    
    return productStatuses;
  }

  ngOnDestroy(): void {
    
  }

}
