import { Component, OnInit } from '@angular/core';
import { 
  FormGroup, 
  FormControl, 
  Validators, 
  FormBuilder, 
  FormArray, 
  FormsModule, 
  ReactiveFormsModule 
} from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { WareHouseService } from 'src/app/services/ware-house.service';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit{

  public isSideInformationVisible: boolean = true;
  public itemId!: number | null;
  public product?: any;
  public formProduct = this.formBuilder.group({
    name : new FormControl(''),
    brands : new FormControl<any>([]),
  });
  // public brands = new FormArray([]);
  public relatedTerms!: any;
  public getPostRelatedTerms!: any;



  constructor(
    private route: ActivatedRoute,
    private wareHouseService: WareHouseService,
    private formBuilder: FormBuilder,
  ) {
  }



  async ngOnInit(): Promise<void> {
    // this.route.queryParams.subscribe(params => { console.warn(params['id']); });
    // this.route.params.subscribe(p => { console.warn(p['id']); });
    // this.wcService.getProduct( this.itemId ).subscribe(product => { console.warn( product ); });
    
    this.itemId               = this.getIdfromRoute();
    
    this.product              = await this.initProduct( this.itemId );
    this.relatedTerms         = await this.initRelatedTerms( this.itemId );
    this.getPostRelatedTerms  = await this.getRelatedTermsByPost( this.itemId );

    this.formProduct.controls.name.setValue( this.product.name );
    this.formProduct.controls.brands.setValue( this.relatedTerms.brand );

    console.warn( {
      product: this.product,
      terms: this.relatedTerms,
      brand: this.relatedTerms.brand,
      postBrand: this.getPostRelatedTerms.brand
    } );

    // console.warn( this.formProduct.controls['brand'].getRawValue() );

  }

  getIdfromRoute(): number {
    return Number( this.route.snapshot.paramMap.get('id') );
  }

  async initProduct(itemId: number): Promise<void> {
    // return await this.wcService.getProduct( itemId );
  }

  async initRelatedTerms( itemId: number ): Promise<void> {
    return await this.wareHouseService.getRelatedTerms();
  }

  async getRelatedTermsByPost( itemId: number ): Promise<void> {
    return await this.wareHouseService.getRelatedTermsByPost( itemId );
  }

  updateOrCreate(): void {
    console.log();
  }


}
