<div class="container-fluid py-4">

    <div class="row mt-6">
      <!-- <div class="" [ngClass]="isSideInformationVisible ? 'col-md-8' : 'col-md-12' "> -->
        <!-- <div class="" [ngClass]="'col-md-8'">
            <app-form-card></app-form-card>
      </div> -->
      <app-inventory-form
        [pageInformation]="pageInformation"
        [productId]="productId"
        [product]="product"
        [brands]="brands"
        [zones]="zones"
        [racks]="racks"
        [locations]="locations"
        [levels]="levels"
        [productCategories]="productCategories"
      >
      </app-inventory-form>


    </div>
  </div>
