<!-- <nav class="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark menu-rouded m-3">
    <div class="container px-4">
      <a class="navbar-brand" routerLinkActive="active" [routerLink]="['/dashboard']">
        <img src="assets/img/brand/prezza_nuova.png" /> {{appName}}
      </a>
      <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
         aria-controls="sidenav-collapse-main">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse"  [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
        
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a routerLinkActive="active" [routerLink]="['/']">
                <img src="assets/img/brand/prezza_nuova.png" /> {{appName}}
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed" >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/home']">
              <i class="ni ni-planet"></i>
              <span class="nav-link-inner--text">Home</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/storia']">
              <i class="ni ni-planet"></i>
              <span class="nav-link-inner--text">Storia</span>
            </a>
          </li>
          
          <div *ngFor="let link of productCategories;" class="mobile-mode">
            <li class="nav-item">
              <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/categorie', link.slug]">
                <i class='fas fa-beer'></i>
                <span class="nav-link-inner--text">{{link.name}}</span>
              </a>
            </li>
          </div>
          <div ngbDropdown class="d-inline-block desktop-mode">
            <li class="nav-item">
              <a class="nav-link nav-link-icon">
                <span class="nav-link-inner--text" id="dropdownBasic1" ngbDropdownToggle
                 style="cursor: pointer;">Strutture Ricettive</span>
              </a>
            </li>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <div *ngFor="let link of productCategories;">
                <button ngbDropdownItem [routerLink]="['/categorie', link.slug]">{{link.title}}</button>
              </div>
            </div>
          </div>
          <hr class="mobile-mode m-0">
          <li class="nav-item">
            <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/contatti']">
              <i class="ni ni-planet"></i>
              <span class="nav-link-inner--text">Contatti</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
</nav> -->


<!-- Navbar -->
<nav class="navbar navbar-expand-lg blur border-radius-lg top-0 z-index-11 shadow position-absolute mt-4 py-2 start-0 end-0 mx-4">
  <div class="container-fluid">
    <a class="navbar-brand font-weight-bolder ms-lg-0 ms-3 " routerLinkActive="active" [routerLink]="['/dashboard']">
      <img src="assets/img/brand/prezza_nuova.png" width="35" /> {{appName}}
    </a>
    <!-- <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" 
      aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation" (click)="toggle()">
      <span class="navbar-toggler-icon mt-2">
        <span class="navbar-toggler-bar bar1"></span>
        <span class="navbar-toggler-bar bar2"></span>
        <span class="navbar-toggler-bar bar3"></span>
      </span>
    </button> -->
    <button
      type="button"
      class="navbar-toggler shadow-none ms-2"
      (click)="collapse.toggle()"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample"
    >
      <span class="navbar-toggler-icon mt-2">
        <span class="navbar-toggler-bar bar1"></span>
        <span class="navbar-toggler-bar bar2"></span>
        <span class="navbar-toggler-bar bar3"></span>
      </span>
    </button>
    <div #navigation class="collapse navbar-collapse" id="navigation" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <ul class="navbar-nav mx-auto">

        <li class="nav-item">
          <a class="nav-link me-2 text-white" href="">
            <i class="fa fa-user opacity-6 text-dark me-1"></i>
            Home
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link me-2 text-white" href="" [routerLink]="['/storia']">
            <i class="fa fa-user opacity-6 text-dark me-1"></i>
            Storia
          </a>
        </li>
        <li ngbDropdown class="nav-item">
            <a type="button" class="nav-link me-2 text-white" id="dropdownBasic1" ngbDropdownToggle>
              Strutture ricettive
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="overflow: hidden;">
              <button ngbDropdownItem *ngFor="let link of productCategories;">
                <a class="nav-link d-flex align-items-center me-2 active" aria-current="page" href=""
                  [routerLink]="['/categorie', link.slug]"
                >
                  <i class="fa fa-chart-pie opacity-6 text-dark me-1"></i>
                  {{link.title | titlecase}}
                </a>
              </button>
            </div>
        </li>
        <li class="nav-item">
          <a class="nav-link me-2 text-white" href="" [routerLink]="['/contatti']">
            <i class="fa fa-user opacity-6 text-dark me-1"></i>
            Contatti
          </a>
        </li>


        <!-- <li class="nav-item" *ngFor="let link of productCategories;">
          <a class="nav-link d-flex align-items-center me-2 active" aria-current="page" href=""
            [routerLink]="['/categorie', link.slug]"
          >
            <i class="fa fa-chart-pie opacity-6 text-dark me-1"></i>
            {{link.title | titlecase}}
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link d-flex align-items-center me-2 active" aria-current="page" href="../pages/dashboard.html">
            <i class="fa fa-chart-pie opacity-6 text-dark me-1"></i>
            Dashboard
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link me-2" href="../pages/profile.html">
            <i class="fa fa-user opacity-6 text-dark me-1"></i>
            Profile
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link me-2" href="../pages/sign-up.html">
            <i class="fas fa-user-circle opacity-6 text-dark me-1"></i>
            Sign Up
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link me-2" href="../pages/sign-in.html">
            <i class="fas fa-key opacity-6 text-dark me-1"></i>
            Sign In
          </a>
        </li> -->
      </ul>
      <!-- <ul class="navbar-nav d-lg-block d-none">
        <li class="nav-item">
          <a href="https://www.creative-tim.com/product/argon-dashboard" class="btn btn-sm mb-0 me-1 btn-primary">
            Free Download
          </a>
        </li>
      </ul> -->
    </div>
  </div>
</nav>
<!-- End Navbar -->
