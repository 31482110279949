import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, interval, take, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  path: string = `${environment.apiBasePath}`;
  route: string = '/posts';
  headers!: HttpHeaders;


  constructor(
    protected http: HttpClient
  ) {
    this.buildHeaders();
  }

  create(body: any): Observable<any> {
    return this.http.post<any>( `${this.path}${this.route}`, body, { headers: this.headers});
  }

  findAll(): Observable<any> {
    return this.http.get<any>( `${this.path}${this.route}`, { headers: this.headers});
  }

  findOne(id: string): Observable<any> {
    return this.http.get<any>( `${this.path}${this.route}/${id}`, { headers: this.headers});
  }

  updateOne(id: string, body: any): Observable<any> {
    return this.http.patch<any>( `${this.path}${this.route}/${id}`, body, { headers: this.headers});
  }

  // async getProducts(): Promise<any> {    
  //   const products = await firstValueFrom(
  //     // this.http.get<WCProduct>(`${this.path}${this.products}?slug=${postSlug}`, {headers: this.headers}
  //     this.http.get<any>( `${this.path}${this.products}`, { headers: this.headers})
  //       .pipe(
  //         map(products => {
  //           // return new WCProduct(products[0]);
  //           return products;
  //         })
  //   ));
  //   return products;
  // }


  buildHeaders(): void {
    this.headers = new HttpHeaders();
    // this.headers.append( 'Authorization', 'Basic Y2tfZDk5YmRkZWM1ZTc3MzUxYjI5ODRjODAwYzBlNWM1YTU5MTZhYmQyYzpjc19jYjIyMzMwOTFjYjgzNWYyNjkyYzQ5ZDY2NTViNDgzNmVkOTNjZjJh' );
    this.headers = this.headers.append( 
      'Authorization', 
      'Basic Y2tfMjRjYjI2OGQxNzE2NGIzMWVlNzU2N2U3Yjk3Njc1MWYyMDE0NTY5Mzpjc19hOTU3ODk3ZDUxYzE2MDNkNDM4NmQzNzQ2ZGEyYjI4MTQ3ZjE2ZDEy' 
    );
    // console.log(this.headers);
  }
}
