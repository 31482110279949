import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services-common/common.service';

@Component({
  selector: 'app-spinner-one',
  templateUrl: './spinner-one.component.html',
  styleUrls: ['./spinner-one.component.css']
})
export class SpinnerOneComponent implements OnInit, OnDestroy {
  public isVisible: boolean = false;

  constructor(
    private commonService: CommonService
  ){}

  ngOnInit() {
    this.commonService
      .aClickedEvent
      .subscribe((data:boolean) => {
        this.isVisible = data;
        console.log('Event message from Component A: ' + data);
      });
  }

  ngOnDestroy(): void {
    
  }
}
