import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  path: string = `${environment.apiBasePath}`;
  route: string = '/files';
  headers!: HttpHeaders;


  constructor(
    protected http: HttpClient
  ) {
    this.buildHeaders();
  }


  // create(body: any): Observable<any> {
  async create(body: any): Promise<any> {
    // return this.http.post<any>( `${this.path}${this.route}`, body, { headers: this.headers});
    const response = await firstValueFrom( 
      this.http
        .post<any>(`${this.path}${this.route}`, body, {headers: this.headers})
        .pipe(
          map(data => {
            return data;
          })
        )
    );
    return response;
  }


  buildHeaders(): void {
    this.headers = new HttpHeaders();
    // this.headers.append( 'Authorization', 'Basic Y2tfZDk5YmRkZWM1ZTc3MzUxYjI5ODRjODAwYzBlNWM1YTU5MTZhYmQyYzpjc19jYjIyMzMwOTFjYjgzNWYyNjkyYzQ5ZDY2NTViNDgzNmVkOTNjZjJh' );
    this.headers = this.headers.append( 
      'Authorization', 
      'Basic Y2tfMjRjYjI2OGQxNzE2NGIzMWVlNzU2N2U3Yjk3Njc1MWYyMDE0NTY5Mzpjc19hOTU3ODk3ZDUxYzE2MDNkNDM4NmQzNzQ2ZGEyYjI4MTQ3ZjE2ZDEy' 
    );
    // console.log(this.headers);
  }
}
