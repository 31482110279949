import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline-one',
  templateUrl: './timeline-one.component.html',
  styleUrls: ['./timeline-one.component.css']
})
export class TimelineOneComponent implements OnInit, OnDestroy {

  @Input() timelineTitle!: string;
  @Input() steps!: any[];

  constructor(){}


  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
}
