import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, interval, take, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ProductAndServicesService {

  path: string = `${environment.apiBasePath}`;
  products: string = '/products';
  categories: string = '/categories';
  productsCategories = '/product-categories';
  headers!: HttpHeaders;
  // headers: HttpHeaders = new HttpHeaders();

  
  wpAuthCredentials =  {
    username: `${environment.wooCommerceConsumerKey}`,
    password: `${environment.wooCommerceConsumerSecret}`
  };


  constructor(
    private http: HttpClient
  ) {
    this.buildHeaders();
  }


  async getProducts(): Promise<any> {    
    const products = await firstValueFrom(
      // this.http.get<WCProduct>(`${this.path}${this.products}?slug=${postSlug}`, {headers: this.headers}
      this.http.get<any>( `${this.path}${this.products}`, { headers: this.headers})
        .pipe(
          map(products => {
            // return new WCProduct(products[0]);
            return products;
          })
    ));
    return products;
  }

  async getProduct(postId: number): Promise<any> {
    const product = await firstValueFrom( 
      this.http
        .get<any>(`${this.path}${this.products}/${postId}`, {headers: this.headers})
        .pipe(
          map(product => {
            return product;
          })
        )
    );
    return product;
  }

  async getProductBySlug( postSlug: string ): Promise<any> {
    const productBySlug = await firstValueFrom(this.http.get<any>(`${this.path}${this.products}/slug/${postSlug}`, {headers: this.headers})
        .pipe(
          map(productBySlug => {
            return productBySlug;
          })
        ));
    return productBySlug
  }
  
  async getProductsCategories(): Promise<any> {
    // // return this.http.get<any>(`${this.path}${this.products}${this.categories}`, {headers: this.headers});
    // const productsCategories = await firstValueFrom(this.http.get<any>(
    //     `${this.path}${this.productsCategories}`, {headers: this.headers}
    //     )
    //     .pipe(
    //       map(productsCategories => {
    //         // return new WCBaseCategory(productsCategories);
    //         console.log(productsCategories);
    //         return productsCategories.data;
    //       })
    //     ));
      const productsCategories = await firstValueFrom(this.http.get<any[]>(
          `${this.path}${this.productsCategories}`, {headers: this.headers}
      ));
      // console.log(productsCategories);
      return productsCategories;
  }

  async getProductCategoryBySlug(categorySlug: string): Promise<any> {
    const productsCategory = await firstValueFrom(this.http.get<any>(`${this.path}${this.productsCategories}/${categorySlug}`, 
      {headers: this.headers}
    )
    .pipe(
      map(productsCategory => {
        // return new WCBaseCategory(productsCategories);
        return productsCategory;
      })
    ));
    return productsCategory;

  }

  async getProductsCategoryIdByCategorySlug(categorySlug: string): Promise<any> {
    const categoryProductsBySlug = await firstValueFrom(this.http.get<any>(`${this.path}${this.products}${this.categories}?slug=${categorySlug}`, {headers: this.headers})
        .pipe(
          map(theCategoryBySlug => {
            return theCategoryBySlug
          })
        ));
    return categoryProductsBySlug;
  }

  async getProductsByCategoryId(categoryId: number): Promise<any[]> {
    console.warn(categoryId);
    // return this.http.get<any>(`${this.path}${this.products}?category=${categoryId}`, {headers: this.headers});
    const productsByCategoryId = await firstValueFrom(this.http.get<any[]>(`${this.path}${this.products}?category=${categoryId}`, {headers: this.headers}))
        // .pipe(
        //   map((productsByCategoryId, index) => {
        //     return productsByCategoryId;
        //   })
        // ));
    return productsByCategoryId.map((data: any) => {
      return data;
    });
  }

  async getProductsByCategorySlug( categorySlug: string): Promise<any> {
    // return this.http.get<any>(`${this.path}${this.products}?category=${categoryId}`, {headers: this.headers});
    const getProductsByCategorySlug = await firstValueFrom(this.http.get<any>(`${this.path}${this.productsCategories}/${categorySlug}`, {headers: this.headers})
        .pipe(
          map(products => {
            return products;
          })
        ));
    // return productsByCategoryId.map((data: any) => {
    //   return data;
    // }
    return getProductsByCategorySlug;
  }

  
  
  /* ************************ WITH OBSERVABLE ************************ */
  getProductsObs(): Observable<any> {   
    return this.http.get<any>( `${this.path}${this.products}`, { headers: this.headers});
  }

  getProductObs(postId: number): Observable<any> {
    return this.http.get<any>(`${this.path}${this.products}/${postId}`, {headers: this.headers});
  }

  getProductCategoryObs(categoryId: number): Observable<any> {
    return this.http.get<any>(`${this.path}${this.products}${this.categories}${categoryId}`, {headers: this.headers});
  }

  
  /* ************************ COMMON FUNCTIONS ************************ */
  buildHeaders(): void {
    this.headers = new HttpHeaders();
    // this.headers.append( 'Authorization', 'Basic Y2tfZDk5YmRkZWM1ZTc3MzUxYjI5ODRjODAwYzBlNWM1YTU5MTZhYmQyYzpjc19jYjIyMzMwOTFjYjgzNWYyNjkyYzQ5ZDY2NTViNDgzNmVkOTNjZjJh' );
    this.headers = this.headers.append( 
      'Authorization', 
      'Basic Y2tfMjRjYjI2OGQxNzE2NGIzMWVlNzU2N2U3Yjk3Njc1MWYyMDE0NTY5Mzpjc19hOTU3ODk3ZDUxYzE2MDNkNDM4NmQzNzQ2ZGEyYjI4MTQ3ZjE2ZDEy' 
    );
    // console.log(this.headers);
  }
}
