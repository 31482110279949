import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { FormGroup, FormControl } from '@angular/forms';
import { WCProduct } from 'src/app/models/wc-product';
import { CommonService } from 'src/app/services-common/common.service';
import { FileService } from 'src/app/services-common/file.service';
import { PostService } from 'src/app/services-common/post-service';
import { TaxonomyService } from 'src/app/services-common/taxonomy.service';
import { environment } from 'src/environments/environment';
import { PostStatusEnum } from 'src/app/Enums/PostStatusEnum';
import { PostTypeEnum } from 'src/app/Enums/PostTypeEnum';


@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css'],
  providers: [MessageService]
})
export class PostComponent implements OnInit, OnDestroy {

  public product!: any;
  public products!: any[];
  public terms?: any[];
  public categories!: any[];
  public postTypes!: string[];
  public postStatuses!: string[];

  form = new FormGroup({
    type: new FormControl(''),
    status: new FormControl(''),
  });

  constructor(
    private titleService:Title,
    private commonService: CommonService,
    private postService: PostService,
    private messageService: MessageService,
    private taxonomyService: TaxonomyService,
    private fileService: FileService
  ) { }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(`${environment.appName}`);
    await this.initTerms();
    await this.initProducts();
    this.buildTaxonomies();

    this.postTypes = this.getPostTypes();
    this.postStatuses = this.getPostStatuses();
  }

  async initProducts(): Promise<void> {
    this.commonService.AClicked(true);
      this.postService
        .findAll()
        .subscribe((data: any) => {
          // console.warn(data);
          this.products = data;
          // console.log(this.products);
          this.commonService.AClicked(false);
        });
  }

  async initTerms(): Promise<void> {
    // this.terms = await this.wpTermsService.getTerms();
    // this.activityName = this.product.name;
    console.log(this.terms);
  }

  search(){
    alert('mimmo');
  }

  buildTaxonomies() {
    this.taxonomyService.findAll('categories')
    .subscribe((data: any) => {
        // console.warn(data);
        this.categories = data;
    });
  }

  
  getPostTypes() {
    const postTypes = Object.keys(PostTypeEnum).filter((item) => {
      return isNaN(Number(item));
    });
    // console.log(productTypes.join("\n"));
    
    return postTypes;
  }

  getPostStatuses() {
    const postStatuses = Object.keys(PostStatusEnum).filter((item) => {
      return isNaN(Number(item));
    });
    // console.log(productStatuses.join("\n"));
    
    return postStatuses;
  }

  ngOnDestroy(): void {
    
  }

}
