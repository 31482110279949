import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageInformationModel } from 'src/app/models/page-information.model';
import { BaseComponent } from '../base/base.component';
import { FormGroup, FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { TaxonomyService } from 'src/app/services-common/taxonomy.service';
import { SlugUtils } from 'src/app/utils-common/SlugUtils';
import { CommonService } from 'src/app/services-common/common.service';
import { TaxonomyEnum } from 'src/app/Enums/TaxonomyEnum';
import { Observable } from 'rxjs';
import { encode } from 'base64-arraybuffer';
import { FileService } from 'src/app/services-common/file.service';



@Component({
  selector: 'app-taxonomies-create',
  templateUrl: './taxonomies-create.component.html',
  styleUrls: ['./taxonomies-create.component.scss']
})
export class TaxonomiesCreateComponent extends BaseComponent implements OnInit, OnDestroy {

  public taxonomy!: string;
  public productId!: string;
  allowedTaxonomies: string[] = [
    TaxonomyEnum.BRANDS,
    TaxonomyEnum.CATEGORIES,
    TaxonomyEnum.ZONES,
    TaxonomyEnum.RACKS,
    TaxonomyEnum.LOCATIONS,
    TaxonomyEnum.LEVELS,
    TaxonomyEnum.PRODUCT_CATEGORIES,
  ];

  status: "initial" | "uploading" | "success" | "fail" = "initial"; // Variable to store file status
  file: File | null = null; // Variable to store file
  fileCoded!: any;
  environment: any = environment;

  
  form = new FormGroup({
    title: new FormControl(''),
    slug: new FormControl(''),
    featured_image: new FormControl(''),
    content: new FormControl(''),
  });

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private fileService: FileService,
    private taxonomyService: TaxonomyService,
    private commonService: CommonService
  ) {
    super();
  }


  ngOnInit(): void {
    // this.activatedRoute.params.subscribe((params) => {
    //     let id = params.get('typeId');
    // });
    // let id = this.activatedRoute.snapshot.params.typeId;

    // let all = this.route.url.subscribe( val => {
    //   let baseRoute = val[0].path;
    //   this.activityType = baseRoute;
    // });

    this.taxonomy = this.route.snapshot.params['taxonomy'];
    this.checkTaxonomyEnabled(this.taxonomy);

    if (this.taxonomy) {
      this.pageInformation = this.buildPageInformation(this.taxonomy);
      this.titleService.setTitle(`${environment.appTitle} - ${this.taxonomy}`);
    }

    this.productId = this.route.snapshot.params['id'];
    if (this.productId) {
      this.initEntity();
      console.warn( this.productId );
    }
  }

  checkTaxonomyEnabled(taxonomy: string): void {
    if (!this.allowedTaxonomies.includes(this.taxonomy)) {
      document.location.href = 'https://google.it';
    }
  }

  async initEntity(): Promise<void> {
    this.commonService.AClicked(true);
      this.taxonomyService
        .findOne(this.taxonomy, this.productId)
        .subscribe((data: any) => {
          console.log(data);
          this.form.patchValue(data);
          this.commonService.AClicked(false);
        });
  }

  async uploadFile(event: any): Promise<any> {
    const file: File = event.target.files[0];

    if (file) {
      this.status = "initial";
      this.file = file;
      this.fileCoded = await this.transformAttachments( event.target.files, {
        folder      : this.form.controls['slug'].value,
        entityType  : this.taxonomy?.toLowerCase(),
      } );
      this.form.patchValue({featured_image: this.fileCoded[0].name})
      console.warn(this.fileCoded);
    }
  }

  async transformAttachments( files: any, productData: any ){
      let filesTransformed: any = [];

      for (let index = 0; index < files.length; index++) {
          let item = files[index];
          let fileContent = await this.getBase64EncodedFileData( item );
          let file = {
              name      : item.name,
              tipology  : item.type,
              type      : item.name.split('.').pop(),
              data      : fileContent,
              folder    : productData.folder,
              entityType: productData.entityType
          };
          filesTransformed.push( file );
      }
      return filesTransformed;
  }

  public getBase64EncodedFileData(file: File): Promise<any> {
      return new Observable(observer => {
          const reader = new FileReader();

          reader.onload = (): void => {
              const {result} = reader;
              const data = result as ArrayBuffer;
              const base64Encoded = encode(data);

              observer.next(base64Encoded);
              observer.complete();
          };

          reader.onerror = (): void => {
              observer.error(reader.error);
          };

          reader.readAsArrayBuffer(file);
      }).toPromise();
  }

  async save(): Promise<void> {
    // this.commonService.AClicked(true);
    console.log({
      file: this.fileCoded,
      form: this.form.value
    });
    // await this.fileService.create(this.fileCoded);
    // if (this.taxonomy) {
    //   this.taxonomyService
    //     .createTaxonomyItem(this.taxonomy, this.form.value)
    //     .subscribe((data: any) => {
    //       console.warn(data);
    //       this.commonService.AClicked(false);
    //     });
    // }
  }

  async update(): Promise<void> {
    this.commonService.AClicked(true);
    // console.log({
    //   file: this.fileCoded,
    //   form: this.form.value
    // });
    await this.fileService.create(this.fileCoded);
    if (this.taxonomy) {
      this.taxonomyService
        .updateOne(this.taxonomy, this.form.value, this.productId)
        .subscribe((data: any) => {
          console.warn(data);
          this.commonService.AClicked(false);
        });
    }
  }

  ngOnDestroy(): void {
    
  }
}
