import {Component, OnInit, Input, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  
  
  @Input() footerMenu!: Record<string, any>[];
  // @Input();
  // @Input();
  // @Input();
  // @Input();
  test: Date = new Date();




  constructor() { }

  ngOnInit() {
    // console.warn(this.footerMenu);
  }

  ngOnDestroy(): void {
    
  }

}
