<!-- <div class="header bg-hero py-7 py-lg-8"> -->
<div class="header py-7 py-lg-8 demo-wrap bg-hero" style="--radius: url('{{url}}');">
    <div class="container demo-content">
        <div class="header-body text-center mb-7">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
            <h1>{{title}}</h1>
            <p class="text-lead" style="font-size: 1.2rem; font-weight: 600;">{{subTitle}}</p>
            </div>
        </div>
        </div>
    </div>
    <!-- <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
    </div> -->
</div>