import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styleUrls: ['./contatti.component.scss']
})
export class ContattiComponent implements OnInit {

  constructor(
    private titleService:Title
  ) { 
    this.titleService.setTitle(`Visitareprezza.it - contatti e credits`);
  }

  ngOnInit(): void {
  }

}
